(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('VerifyPinCtrl', [
        '$scope', '$state', '$stateParams', '_', '$q', 'selectedDoc', 'documentsService', '$modal', '$modalInstance', '$sce', 'signatureService', 'constants', 'renew', function($scope, $state, $stateParams, _, $q, selectedDoc, documentsService, $modal, $modalInstance, $sce, signatureService, constants,renew) {

        $scope.title = "Verify Request to Sign";
        $scope.message = "Please Enter the PIN code you received (via email):";
        $scope.confirmBtnText = "Confirm";
        $scope.cancelBtnText = "Cancel";
        $scope.document = selectedDoc;
        $scope.pin = "";
        $scope.warn = false;
        $scope.warning = "";
        $scope.renewLock = renew;
        $scope.renewWarning = "The signing time limit on the document has expired, please verify again to complete the signing of your document"; 

        $scope.confirm = function() {
            $scope.warn = false;
            var dataObj = { DocumentId: $scope.document.Id, Pin: $scope.pin };
            if ($scope.pin != "") {
                if ($scope.document.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.SECURESIGN) {
                    signatureService.verifyMultipleSignaturePin(dataObj).then(function (result) {
                        if (_.isUndefined(result) || result === null) {
                            $scope.warn = true;
                            $scope.warning = $sce.trustAsHtml("An error occurred while processing your request please try again.");
                        } else 
                        if (result.Message === "doc in use") {
                            $scope.warn = true;
                            $scope.warning = $sce.trustAsHtml("The document you have requested is currently being signed by another user, please wait 5 minutes and try again.");
                        } else {
                            if (result.Message === "alreadySigned") {
                                $scope.warn = true;
                                $scope.warning = $sce.trustAsHtml("You are not required to sign this document. <br> Possible Reasons: <br>- Already Signed. <br>- Not one of the selected accounts to sign. <br>Please double check you are required to sign the document.");
                            } else {
                                if (result.status === true) {
                                    if (!_.isUndefined(result.locations)) {
                                        $modalInstance.dismiss('cancel');
                                        $modal.open({
                                            template: require('Documents\\MultipleSignatures\\MultipleSignatureSign.html'),
                                            controller: 'MultipleSignatureSignCtrl',
                                            resolve: {
                                                document: function() {
                                                    return $scope.document;
                                                },
                                                signatureLocation: function() {
                                                    return result.locations;
                                                }
                                            },
                                            size: 'lg',
                                            backdrop: 'static'
                                        });
                                    } else {
                                        $scope.warn = true;
                                        $scope.warning = $sce.trustAsHtml("An error occurred while processing your request please try again.");
                                    }
                                } else {
                                    $scope.warn = true;
                                    $scope.warning = $sce.trustAsHtml("The PIN code you have entered could not be verified, please try again.");
                                }
                            }
                        }

                    });
                }
                if ($scope.document.ActionTypeE === constants.DOCUMENTS.ACTIONTYPES.CLICKTOSIGN) {
                    signatureService.verifyClickToSign(dataObj).then(function (result) {
                        if (_.isUndefined(result) || result === null) {
                            $scope.warn = true;
                            $scope.warning = $sce.trustAsHtml("An error occurred while processing your request please try again.");
                        } else
                        if (result.Message === "doc in use") {
                            $scope.warn = true;
                            $scope.warning = $sce.trustAsHtml("The document you have requested is currently being signed by another user, please wait 5 minutes and try again.");
                        } else {
                            if (result.Message === "alreadySigned") {
                                $scope.warn = true;
                                $scope.warning = $sce.trustAsHtml("You are not required to sign this document. <br> Possible Reasons: <br>- Already Signed. <br>- Not one of the selected accounts to sign. <br>Please double check you are required to sign the document.");
                            } else {
                                if (result === true) {
                                    var fileIdToSign = $scope.document.Id;
                                    var fileTitle = $scope.document.Title;
                                    $modalInstance.dismiss('cancel');
                                    $modal.open({
                                        template: require('Documents\\ClickToSign\\ClickToSignModal.html'),
                                        controller: 'ClickToSignModalCtrl',
                                        resolve: {
                                            document: function () {
                                                return $scope.document;
                                            }
                                        },
                                        size: 'lg',
                                        backdrop: 'static'
                                    });
                                } else {
                                    $scope.warn = true;
                                    $scope.warning = $sce.trustAsHtml("The PIN code you have entered could not be verified, please try again.");
                                }
                            }
                        }
                    });
                }
            } else {
                $scope.warn = true;
                $scope.warning = $sce.trustAsHtml("Please type your PIN code in the input box.");
            }
        }
    
            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            }

        }
    ]);
})();
