(function() {
    'use strict';
    var module = angular.module('bf.services.documents.user');

    module.service('documentFiltersService', [
        '_', '$q', '$timeout', 'events', 'constants', 'dataStoreService', 'ENVIRONMENTAL', function(_, $q, $timeout, events, constants, dataStoreService, ENVIRONMENTAL) {
            
            var apiSection = "/documentFilters/";

            function localGet(clientId) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + clientId);
            }

            function addFilter(clientId, text, parentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(text)) {
                    return dataStoreService.addResource(ENVIRONMENTAL.BASEURLS.API + apiSection + clientId, { index: "0", text: text, parentId: parentId });
                }
                deffered.reject();
                return deffered.promise;
            }

            function removeFilter(id) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.deleteResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
                }
                deffered.reject();
                return deffered.promise;
            }

            function updateFilter(id, text) {
                var deffered = $q.defer();
                if (!_.isUndefined(id)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, { id: id, index: "0", text: text });
                }
                deffered.reject();
                return deffered.promise;
            }

            return {
                get: localGet,
                add: addFilter,
                remove: removeFilter,
                update: updateFilter
            }
        }
    ]);
})();