angular.module('app')
    .controller('ProfileCtrl', ['$scope', '$location', '$window', 'forgotPasswordService', 'ENVIRONMENTAL', '_',
        function ($scope, $location, $window, forgotPasswordService, ENVIRONMENTAL, _) {
            $scope.$on('$viewContentLoaded', function (event) {
                $scope.isLoading = false;
                $scope.progress.complete();
                $scope.showPassword = false;
                $scope.showConfirmPassword = false;
                $scope.satisfied = false;

                $scope.manageProfile = function () {
                    var identityManageUrl = 'https://' + ENVIRONMENTAL.AUTH0.DOMAIN + '/PortalManage/Index';
                    window.open(identityManageUrl, '_blank');
                };
            })
        }
    ]);