(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('ClickToSignModalCtrl', [
        '$scope', '$modalInstance', 'document', '$sce', 'constants', 'documentsService', '$q', 'ENVIRONMENTAL', '$window', 'events', 'uploadService', '$interval', 'store', 'signatureService', '$modal', 'logService', function ($scope, $modalInstance, document, $sce, constants, documentsService, $q, ENVIRONMENTAL, $window, events, uploadService, $interval, store, signatureService, $modal, logService) {

            $scope.title = document.Title;
            $scope.signingComplete = false;
            $scope.signingFailed = false;

            $scope.close = function () {
                $modalInstance.dismiss('cancel');
            }
            $scope.uploading = false;
            $scope.uploadingProgress = 0;
            $scope.messageIntegrity = false;
            $scope.documentLoading = true;
            $scope.signingFailed = false;
            $scope.lock = false; // lock to ensure only one api request is made, postMessages often duplicate.

            $scope.reloadSignatureDocument = function () {
                signatureService.renewLockForSigning(document.Id).then(function (result) {
                    if (result !== true) {
                        $modalInstance.close('finished');
                        $modal.open({
                            template: require('Documents\\MultipleSignatures\\VerifyPinBeforeSigningModal.html'),
                            controller: 'VerifyPinCtrl',
                            resolve: {
                                selectedDoc: function() {
                                    return document;
                                },
                                renew: function() {
                                    return true;
                                }
                            },
                            size: 'lg'
                        });
                    } else {
                        $scope.uploading = false;
                        $scope.uploading = false;
                        $scope.documentLoading = true;
                        $scope.signingFailed = false;
                        $scope.signingComplete = false;
                        $scope.messageIntegrity = false;
                        $("#frame")[0].style.visibility = "hidden";

                        setFrameUrl();
                    }
                });

            }

            var messageHandler = function (e) {
                if (e.originalEvent.data === "signingComplete" && $scope.signingComplete === false) {
                    $scope.uploading = false;
                    setSigningCompletion(false);
                    $scope.uploadingProgress = 100;
                }
                if (e.originalEvent.data === "uploading" && $scope.uploading === false) {
                    $scope.uploading = true;
                    uploadingTimer();
                }
                if (e.originalEvent.data === "signingFailed" && $scope.signingFailed === false) {
                    logService.error(null, "Click To Sign failed flag set on client side for document with id: " + document.Id);
                    setSigningCompletion(true);
                }
                if (e.originalEvent.data === "error" && $scope.signingComplete === false) {
                    logService.error(null, "Click To Sign filed for document with id: " + document.Id + ".");
                    setSigningCompletion(true);
                    $scope.uploading = false;
                }
                if (e.originalEvent.data === "loadingComplete") {
                    if (!$scope.messageIntegrity) {
                        $scope.documentLoading = false;
                        $scope.messageIntegrity = true;
                        $scope.$apply();
                        $("#frame")[0].style.visibility = "visible";
                    }
                }
            };

            var setSigningCompletion = function(failed) {
                $scope.$apply(function() {
                    $scope.signingFailed = failed;
                    $scope.signingComplete = true;
                });
            };

            $($window).on("message", messageHandler);

            $scope.$on('$destroy', function () {
                $($window).off('message', messageHandler);
            });

            $scope.$watch('signingComplete', function (newVal, oldVal) {
                if (newVal && !$scope.signingFailed && !$scope.lock) {
                    logService.information("Signing complete flag set on client side for document with id: " + document.Id);
                    $scope.lock = true;
                    signatureService.updateClickToSign(document.Id).then(function (result) {
                        if (!_.isUndefined(result.Message)) {
                            if (result.Message == "no signatory") {
                                logService.error(null, "Failed response received from api for commiting signed document with id: " + document.Id);
                            } else {
                                logService.error(JSON.Stringify(result), "Unexpected message returned from api for commiting signed document with id: " + document.Id);
                            }
                        } else {
                            logService.information("Successful response received from api for committing signed document with id: " + document.Id);
                            events.trigger(constants.DOCUMENTS.EVENTS.REFRESHREQUIRED);
                        }
                    });
                }
            });

            var uploadingTimer = function() {
                $interval(function() {
                        $scope.uploadingProgress = $scope.uploadingProgress + 5;
                    },
                    1000,
                    20);
            };

            function setFrameUrl() {
                documentsService.getDownloadForSigning(document.Id)
                    .then(function(data) {
                        return signatureService.identifyUser()
                            .then(function() {
                                return data;
                            });
                    })
                    .then(function (data) {
                        var profile = JSON.parse(JSON.stringify(store.get('profile')));
                        var userDetailsToParse = { email: profile.email, firstName: profile.firstName, surname: profile.lastName };
                        var userDetailsParam = "&userDetails=" + encodeURIComponent(JSON.stringify(userDetailsToParse));

                        $scope.url = $sce.trustAsResourceUrl(ENVIRONMENTAL.BASEURLS.SIGN + "clickToSign/index" + constants.DOCUMENTS.URLS.SIGNURL + encodeURIComponent(data.fileUrl) + constants.DOCUMENTS.URLS.SIGNTITLE + "Signing: " + encodeURIComponent(document.Title) + userDetailsParam);
                    });
            }

            setFrameUrl();
        }
    ]);
})();
