(function() {
    'use strict';
    var module = angular.module('bf.services.signature', []);

    module.service('signatureService', [
        '_', '$q', '$timeout', 'momentDateTime', 'events', 'constants', 'dataStoreService', '$http', 'ENVIRONMENTAL', function(_, $q, $timeout, momentDateTime, events, constants, dataStoreService, $http, ENVIRONMENTAL) {

            var apiSection = '/sign/';
            var multipleSignaturesApiSection = '/documents/';


            function localverifyClickToSign(theDataToSend) {
                var deffered = $q.defer();
                if (!_.isUndefined(theDataToSend)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'clicktosign/verify/' + theDataToSend.DocumentId, { Pin: theDataToSend.Pin }).then(function (data) {
                        if (data != null && _.isUndefined(data.ExceptionMessage)) {
                            deffered.resolve(data);
                            return deffered.promise;
                        } else {
                            deffered.resolve(false);
                            return deffered.promise;
                        }
                    });
                } else {
                    deffered.reject();
                    return deffered.promise;
                }
                return deffered.promise;
            }


            function localUpdateClickToSign(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'clicktosign/complete/' + documentId).then(function (data) {
                        deffered.resolve(data);
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function localVerifyDocumentMultipleSignaturePin(theDataToSend) {
                var deffered = $q.defer();
                if (!_.isUndefined(theDataToSend)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + multipleSignaturesApiSection + 'sign/multiple/verify/' + theDataToSend.DocumentId, { Pin: theDataToSend.Pin }).then(function (data) {
                        if (data != null && _.isUndefined(data.ExceptionMessage)) {
                            deffered.resolve(data);
                            return deffered.promise;
                        } else {
                            deffered.resolve(false);
                            return deffered.promise;
                        }
                    });
                } else {
                    deffered.reject();
                    return deffered.promise;
                }
                return deffered.promise;
            }
            function localUpdateMultipleSignatureDocument(documentId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + multipleSignaturesApiSection + 'sign/multiple/' + documentId).then(function (data) {
                        deffered.resolve(data);
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function localRenewLockForSigning(documentId, signatoryId) {
                var deffered = $q.defer();
                if (!_.isUndefined(documentId)) {
                    dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'renewLock/' + documentId).then(function (data) {
                        deffered.resolve(data);
                    });
                } else {
                    deffered.reject();
                }
                return deffered.promise;
            }

            function identifyUser() {
                return $http.get(ENVIRONMENTAL.BASEURLS.SIGN + 'api/users/identify', { withCredentials: true })
                    .catch(function() { }); // Ignore any errors as identifyUser is just used for diagnostics
            }

            return {
                verifyClickToSign: localverifyClickToSign,
                updateClickToSign: localUpdateClickToSign,
                verifyMultipleSignaturePin: localVerifyDocumentMultipleSignaturePin,
                updateMultipleSignatureDocument: localUpdateMultipleSignatureDocument,
                renewLockForSigning: localRenewLockForSigning,
                identifyUser: identifyUser
            };
        }
    ]);
})();
