(function() {
    'use strict';

    angular.module('bf.constants', [])
        .constant('constants', {
            APP: {
                NAME: 'Portal'
            },
            SYSTEM: {
                EVENTS: {
                    GRIDREFRESH: 'GridRefresh',
                    APPSTARTUP: 'AppStartUp'
                },
                SETTINGS: {
                    PREVIEWABLEFILEEXTENSIONS: 'PreviewableFileExtensions'
                }
            },
            DOCUMENTS: {
                URLS: {
                    PREVIEWURL: "?url=",
                    PREVIEWTITLE: "&title=",
                    PREVIEWCOLOR: "&color=",
                    SIGNURL: "?url=",
                    SIGNFINISHEDURL: "&finishedurl=",
                    SIGNTITLE: "&title="
                },
                EVENTS: {
                    SELECTED: 'Selected',
                    SEARCH: 'Search',
                    REFRESHREQUIRED: 'DocumentsRefreshRequired'
                },
                ACTIONTYPES: {
                    NONE: 1,
                    SIGN: 2,
                    SECURESIGN: 3,
                    CLICKTOSIGN: 5,
                    SIGNED: 4,
                    ADOBESIGN: 6,
                    ADOBESECURESIGN: 7
                }
            },
            SECURITY: {
                EVENTS: {
                    LOGGEDIN: 'UserLoggedIn',
                    LOGGEDOUT: 'UserLoggedOut'
                }
            },
            UPLOADING: {
                EVENTS: {
                    START: 'UploadStart',
                    COMPLETE: 'UploadComplete',
                    ABORTED: 'UploadAborted',
                    FILEADDED: 'UploadFileAdded',
                    ERRORED: 'UploadFileErrored',
                    RETRYING: 'UploadFileRetrying',
                    PROGRESSCHANGE: 'UploadFileProgressChange',
                    GETTINGFILEIDENTIFIER: 'GettingFileIdentifier',
                    FILESTATECHANGED: 'UploadFileStateChanged'
                }
            },
            DOWNLOADING: {
                EVENTS: {
                    STARTED: 'DownloadStarted'
                }
            },
            NOTIFICATIONS: {
                TYPES: {
                    ALERT: {
                        ID: 1,
                        CSSCLASS: 'label label-danger',
                        ICONCLASS: 'fa fa-exclamation-triangle'
                    },
                    COMMENT: {
                        ID: 2,
                        CSSCLASS: 'label label-primary',
                        ICONCLASS: 'fa fa-comment'
                    }
                }
            },
            FOLDERS: {
                MODALMODES: {
                    CREATE: 'Create',
                    EDIT: 'Edit',
                    DELETE: 'Delete',
                    ASSIGN: 'Assign',
                    MULTIASSIGN: 'MultiAssign',
                    DELETEMULTI: 'DeleteMultiple'
                },
                EVENTS: {
                    REFRESHREQUIRED: 'FoldersChanged'
                }
            }
        });
})();
