'use strict';
var versionNumber = {name: 'HappyMonkey'};
angular.module('app', [
    'angular-jwt',
    'angular-storage',
    'angularMoment',
    'app.controllers',
    'app.directives',
    'app.services',
    'auth0',
    'bf.components.dashboard.user.activity',
    'bf.components.files',
    'bf.components.notifications.user',
    'bf.components.upload',
    'bf.config.environmental',
    'bf.constants',
    'bf.services.accounts',
    'bf.services.branding',
    'bf.services.clients',
    'bf.services.dashboard.user',
    'bf.services.data',
    'bf.services.dataLayerService',
    'bf.services.documents.user',
    'bf.services.fileDownload',
    'bf.services.forgotPassword',
    'bf.services.log',
    'bf.services.signature',
    'bf.services.systemsettings',
    'common',
    'flow',
    'kendo.directives',
    'loadingPane',
    'ngProgress',
    'ui.bootstrap',
    'ui.router'
]);

angular.module('app')
    .value('version', versionNumber)
    .constant('appName', 'HowNow Portal')
    .constant('URL_CONSTANTS', {
        BaseAPI: 'no-address'
    })
    .config([
        'commonConfigProvider', '$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider', 'authProvider',
        function(cfg, $stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, authProvider) {

            // For any unmatched url, redirect to /login
            $urlRouterProvider.otherwise('/error');

            // Generic states
            $stateProvider
                .state('logout', { url: '/logout', controller: 'LogoutCtrl', template: require('Site\\logout.html'), layout: 'login' })
                .state('login', { url: '/login:partial:theme', controller: 'LoginCtrl', template: require('Site\\login.html'), layout: 'login' })
                .state('callback', { url: '/callback', controller: 'CallbackCtrl', template: require('Site\\Callback.html'), layout: 'callback' })
                .state('accessdenied', { url: '/accessdenied', controller: 'DullCtrl', layout: 'site', template: require('accessdenied.html') })
                .state('signingcomplete', { url: '/signingcomplete', controller: 'DullCtrl', layout: 'site', template: require('site\\signingcomplete.html') })

                //Authenticated states.
                .state('dashboard', { url: '/', data: { requiresLogin: true }, template: require('Start\\start.html'), controller: 'StartCtrl' })
                .state('settings', { url: '/settings/', data: { requiresLogin: true }, template: require('Settings\\settings.html'), controller: 'SettingsCtrl' })
                .state('documents', { url: '/documents/list/:workingClientId/:documentId', data: { requiresLogin: true }, template: require('Documents\\Documents.html'), controller: 'DocumentsCtrl' })
                .state('documents-sign', { url: '/documents/sign/:workingClientId/:documentId', data: { requiresLogin: true, sign: true }, template: require('Documents\\Documents.html'), controller: 'DocumentsCtrl' })
                .state('documents-preview', { url: '/documents/preview/:workingClientId/:documentId', data: { requiresLogin: true, preview: true }, template: require('Documents\\Documents.html'), controller: 'DocumentsCtrl' })
                .state('viewannouncement', { url: '/announcement/:announcementid', controller: 'ViewAnnouncementCtrl', template: require('Announcements\\ViewAnnouncement.html'), data: { requiresLogin: true } })

                // Nested view inside settings
                .state('settings.general', { url: '/general', data: { requiresLogin: true }, template: require('Settings\\General.html'), controller: 'GeneralCtrl' })
                .state('profile', { url: '/profile', data: { requiresLogin: true }, template: require('Site\\Profile.html'), controller: 'ProfileCtrl' })

                .state('accept', { url: '/accept?inviteToken', controller: 'AcceptInviteCtrl', template: require('Site\\AcceptInvite.html') })
                .state('requestPassword', { url: '/requestPassword', controller: 'RequestPasswordCtrl', template: require('Site\\ForgotPassword\\RequestPassword.html') })
                .state('updatePassword', { url: '/updatePassword?token', controller: 'UpdatePasswordCtrl', template: require('Site\\ForgotPassword\\UpdatePassword.html') })

                .state('error', { url: '/error', controller: 'DullCtrl', layout: 'site', template: require('404.html') });

            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            });

            versionNumber.value = cfg.environmental.VERSION;
            var url = window.location.href;

            if (url.includes("documents/sign")) {
                localStorage.setItem("signingUrl", url);
            }

            authProvider.init({
                domain: cfg.environmental.AUTH0.DOMAIN,
                clientID: cfg.environmental.AUTH0.CLIENTID,
                callbackURL: cfg.environmental.AUTH0.CALLBACKURL,
                //loginState: 'login'
            });

            $httpProvider.interceptors.push('customInterceptor');
        }
    ]).config(['flowFactoryProvider', 'ENVIRONMENTAL', function (flowFactoryProvider, ENVIRONMENTAL) {
    flowFactoryProvider.defaults = {
        target: ENVIRONMENTAL.FILEAPI.BASEURL + '/api/upload/uploadchunks',
        permanentErrors: [500, 501],
        maxChunkRetries: 1,
        chunkRetryInterval: 5000,
        simultaneousUploads: 3,
        testChunks: false,
        withCredentials: true//,
        //headers: {'x-ms-blob-type': 'BlockBlob'}
    };
    flowFactoryProvider.on('catchAll', function (event) {
        console.log('catchAll', arguments);
    });
    // Can be used with different implementations of Flow.js
    //flowFactoryProvider.factory = fustyFlowFactory;
}])
    .run([
        'events', 
        'constants', 
        '$templateCache', 
        '$rootScope', 
        '$state', 
        '$stateParams', 
        'auth', 
        'authHelper', 
        '$timeout', 
        'ngProgress', 
        'store', 
        'jwtHelper', 
        '$location', 
        function (
            events,
            constants,
            $templateCache,
            $rootScope,
            $state,
            $stateParams,
            auth,
            authHelper,
            $timeout,
            ngProgress,
            store,
            jwtHelper,
            $location) {

        $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams) {
            ngProgress.reset();
            ngProgress.start();
            $rootScope.isLoading = true;

            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;
            if (window.self !== window.top) {
                if (toState.name !== 'login' && toState.name !== 'signingcomplete') {
                    var partial;
                    var theme;
                    if ($location.$$search.partial != null) {
                        partial = $location.$$search.partial;
                    }

                    if ($location.$$search.partial != null) {
                        theme = $location.$$search.theme;
                    }
                    event.preventDefault();

                    $state.go('login', { partial: partial, theme: theme });
                    return;
                }
            } else {
                if (!auth.isAuthenticated && !($location.url().indexOf("/accounts/loadInvite") >= 0 ||
                    $location.url().indexOf("/accept") >= 0 ||
                    $location.url().indexOf("/requestPassword") >= 0 ||
                    $location.url().indexOf("/updatePassword") >= 0)) {
                    var token = store.get('token');
                    if (token && !jwtHelper.isTokenExpired(token)) {
                        auth.authenticate(store.get('profile'), token);
                    }
                }
            }

            if (toState.data && toState.data.requiresLogin === true && (!auth.isAuthenticated || jwtHelper.isTokenExpired(auth.idToken))) {
                if ($state.current.name !== toState.name) {
                    ngProgress.reset();
                }
                event.preventDefault();
                if (toState && toState.name !== 'login') {
                    $rootScope.returnToState = toState;
                    $rootScope.returnToStateParams = toStateParams;
                }
                $state.go('login');
                return;
            }

            // check the roles
            if ($rootScope.toState.data && $rootScope.toState.data.roles && $rootScope.toState.data.roles.length > 0 && !authHelper.isInAnyRole($rootScope.toState.data.roles)) {
                if (auth.isAuthenticated) {
                    $state.go('accessdenied');
                    return;
                } else {
                    if (toState && toState.name !== 'login') {
                        $rootScope.returnToState = $rootScope.toState;
                        $rootScope.returnToStateParams = $rootScope.toStateParams;
                    }
                    // now, send them to the signin state so they can log in
                    if ($location.$$search.partial) {
                        $state.go('login', { partial: true });
                    } else {
                        $state.go('login');
                    }
                    return;
                }
            }

            // Close the nav bar
            $rootScope.navOpen = false;
        });

        $rootScope.$on('$stateChangeSuccess', function(event, toState) {
            $rootScope.layout = toState.layout;
        });

        $rootScope.$on('$stateChangeError', function() {
            $timeout(function() {
                ngProgress.reset();
                if ($location.$$search.partial) {
                    $state.go('login', { partial: true });
                }
                $state.go('login');
            });
        });

        var customData = {};
        var profile = store.get("profile");
        if (!_.isUndefined(profile) && !_.isNull(profile)) {
            if (!_.isUndefined(profile.userId)) {
                customData.userId = profile.userId;
            }
        }

        auth.hookEvents();

        // Allows to retrieve UI Router state information from inside templates
        $rootScope.title = constants.APP.NAME;
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;

        ngProgress.color("#fff");
        kendo.culture("en-AU");
    }
]);