(function() {
    "use strict";

    angular.module("app")
        .controller("ViewAnnouncementCtrl", [
            "$scope", "constants", "$timeout", "events", "announcementsService", "$state", "$q", "$modal", "_", "$sce", "downloadService", "logService",
            function ($scope, constants, $timeout, events, announcementsService, $state, $q, $modal, _, $sce, downloadService, logService) {

                $scope.$on("$viewContentLoaded", function (event) {
                    announcementsService.getById($state.params.announcementid).then(function (data) {
                        $scope.announcement = data;
                        $scope.announcement.Body = $sce.trustAsHtml($scope.announcement.Body);
                        $scope.isLoading = false;
                        $scope.progress.complete();
                    });
                });

                $scope.previewDocument = function(document) {
                    var branding = $("header.row").css("background-color");
                    if (brandingColour.length > 6 && brandingColour.substr(0, 3) === "rgb") {
                        var rgb = branding.replace(/[^\d,.]/g, "").split(",");
                        branding = toHex(rgb[0]) + toHex(rgb[1]) + toHex(rgb[2]);
                    }
                    $modal.open({
                        template: require('Documents\\DocumentsPreview.html'),
                        controller: "DocumentsPreviewCtrl",
                        resolve: {
                            fileId: function() {
                                return document.Id;
                            },
                            fileTitle: function() {
                                return document.Title;
                            },
                            storagePath: function() {
                                return document.StoragePath;
                            },
                            brandingColour: function() {
                                return branding;
                            }
                        },
                        size: "lg"
                    });
                };

                $scope.downloadDocument = function(document) {

                    downloadService.downloadAnnouncementFile(document.StoragePath).then(function() {
                            $scope.progress.complete();
                        },
                        function(failureReason) {
                            $scope.$parent.showNotification("Download Error",
                                "Failed to initialize download, please try again.",
                                "error");
                            
                            logService.error(failureReason, "Error whilst downloading announcement file " + document.Id);
                        });
                };
            }
        ]);
})();