'use strict';

angular.module('app.services', [])
    .factory('authHelper', [
        'auth', '$q', 'ENVIRONMENTAL', 'constants', 'events', '$rootScope', function (auth, $q, ENVIRONMENTAL, constants, events, $rootScope) {

            var evtLogout = events.on(constants.SECURITY.EVENTS.LOGGEDOUT, function (evt, data) {
                $rootScope.ApiTokenPromise = null;
                $rootScope.FilesTokenPromise = null;
            });

            return {
                isInRole: function(role) {
                    if (!auth.isAuthenticated || !auth.profile.roles) {
                        return false;
                    }

                    return auth.profile.roles.indexOf(role) !== -1;
                },
                isInAnyRole: function(roles) {
                    if (!auth.isAuthenticated || !auth.profile.roles) {
                        return false;
                    }

                    for (var i = 0; i < roles.length; i++) {
                        if (this.isInRole(roles[i])) {
                            return true;
                        }
                    }

                    return false;
                },
                loadProfile: function() {
                    //TODO: Return profile.
                    //return apiService.GetProfile(auth.profile.CentralSub.user.userId)
                    //.then(function (data) {
                    //    auth.workpapersProfile = data;
                    //    return data;
                    //});
                },
                portalProfile: function() {
                    if (!auth.isAuthenticated) {
                        return undefined;
                    }
                    if (auth.workpapersProfile) {
                        return auth.workpapersProfile;
                    }

                    return undefined;
                },
                getFileApiDelegationOptions: function () {
                    return { targetClientId: ENVIRONMENTAL.FILEAPI.CLIENTID, scope: 'openid profile' };
                },
                getDelegatedToken: function (options) {
                    var tokenPromise = auth.getToken(options)
                            .then(function (token) {
                                return token;
                            }, function (err) {
                                return err;
                            });
                    return $q.when(tokenPromise);
                }
            };
        }
    ])
    .factory('customInterceptor', [
        '$injector', '$rootScope', '$q', 'ENVIRONMENTAL', 'store', '_', 'jwtHelper', function ($injector, $rootScope, $q, ENVIRONMENTAL, store, _, jwtHelper) {
            return {
                'request': function(config) {
                    var auth = $injector.get('auth');
                    var state = $injector.get('$state');
                    var options = { targetClientId: ENVIRONMENTAL.MAINAPI.CLIENTID, scope: 'openid profile' };
                    var fileApiOptions = { targetClientId: ENVIRONMENTAL.FILEAPI.CLIENTID, scope: 'openid profile' };
                    config.headers = config.headers || {};
                    var storedToken = null;
                    // Is this request for the secondary app?
                    if (config.url.indexOf(ENVIRONMENTAL.BASEURLS.API) === 0 && !(config.url.indexOf("/accounts/loadInvite") >= 0 ||
                        config.url.indexOf("/accounts/acceptInvite") >= 0 ||
                        config.url.indexOf("/accounts/requestPasswordReset") >= 0 ||
                        config.url.indexOf("/accounts/performPasswordReset") >= 0 ||
                        config.url.indexOf("/accounts/resendInviteByToken") >= 0 || 
                        config.url.indexOf("/accounts/resendInviteByEmail") >= 0)) {
                        // Then fetch the secondary app token
                        storedToken = store.get('tokenAPI');
                        if (_.isNull(storedToken) || _.isUndefined(storedToken)) {
                            if (_.isNull($rootScope.ApiTokenPromise) || _.isUndefined($rootScope.ApiTokenPromise)) {
                                $rootScope.ApiTokenPromise = auth.getToken(options)
                                    .then(function(token) {
                                        store.set('tokenAPI', token);
                                    }, function(err) {
                                        // Handle error fetching token here
                                        state.go("login");
                                        return err;
                                    });
                            }
                            var ApiDefer = $q.defer();
                            $rootScope.ApiTokenPromise.then(function() {
                                storedToken = store.get('tokenAPI');
                                if (_.isNull(storedToken) || _.isUndefined(storedToken)) {
                                    ApiDefer.reject({ status: 401 });
                                } else {
                                    // Throw the user out as the token has expired
                                    if (jwtHelper.isTokenExpired(storedToken)) {
                                         ApiDefer.reject({ status: 401 });
                                    }
                                    config.headers.Authorization = 'Bearer ' + storedToken;
                                    ApiDefer.resolve(config);
                                }
                            });
                            return $q.when(ApiDefer.promise);
                        }
                        if (jwtHelper.isTokenExpired(storedToken)) return $q.reject({ status: 401 });
                    config.headers.Authorization = 'Bearer ' + storedToken;
                    return config;
                } else if (config.url.indexOf(ENVIRONMENTAL.FILEAPI.BASEURL) === 0) {
                    // Then fetch the secondary app token
                    storedToken = store.get('tokenFiles');
                    if (_.isNull(storedToken) || _.isUndefined(storedToken)) {
                        if (_.isNull($rootScope.FilesTokenPromise) || _.isUndefined($rootScope.FilesTokenPromise)) {
                            $rootScope.FilesTokenPromise = auth.getToken(fileApiOptions)
                                .then(function (token) {
                                    store.set('tokenFiles', token);
                                }, function (err) {
                                    // Handle error fetching token here
                                    state.go("login");
                                    return err;
                                });
                        }
                        var defer = $q.defer();
                        $rootScope.FilesTokenPromise.then(function () {
                            storedToken = store.get('tokenFiles');
                            if (_.isNull(storedToken) || _.isUndefined(storedToken)) {
                                // Throw the user out as we can't tell if they are authenticated
                                defer.reject({ status: 401 });
                            }
                            // Throw the user out as the token has expired
                            if (jwtHelper.isTokenExpired(storedToken)) return defer.reject({ status: 401 });
                            config.headers.Authorization = 'Bearer ' + storedToken;
                            defer.resolve(config);
                        });
                        return $q.when(defer.promise);
                    }
                    if (jwtHelper.isTokenExpired(storedToken)) return $q.reject({ status: 401 });
                    config.headers.Authorization = 'Bearer ' + storedToken;
                    return config;
                    } else {
                        if (auth.idToken) {
                            config.headers.Authorization = 'Bearer ' + auth.idToken;
                        }
                        return config;
                    }
                },
                responseError: function (response) {
                    var state = $injector.get('$state');
                    // handle the case where the user is not authenticated
                    if (response.status === 401 && state.current.url !== "/accept?inviteToken") {
                        $rootScope.$broadcast('auth:forbidden', response);
                        state.go("logout");
                    }

                    try {
                        // Note that logService.error goes via $http too. Don't get into a logging loop!
                        if (response.config.url.indexOf('/log/' === -1)) {
                            var logService = $injector.get('logService');
                            var logMessage = "UserPortal client request resulted in rejected promise: " + response.config.url;
                            var details = {
                                stackTrace: new Error().stack,
                                data: response.data,
                                url: response.config.url,
                                status: response.status
                            };
                            logService.error(null, logMessage, details);
                        }
                    } catch (e) {
                        // Burp.
                    }
                    
                    return $q.reject(response);
                },
                authHeader: function() {
                    var auth = $injector.get('auth');
                    var targetClientId = 'rI8zJSYMqZFL2iylXrLuH1bcuuplY0kq';
                    var options = { scope: 'openid profile' };

                    // Then fetch the secondary app token
                    var tokenPromise = auth.getToken(targetClientId, options, true)
                        .then(function(token) {
                            return 'Bearer ' + token;
                        }, function(err) {
                            // Handle error fetching token here
                            return err;
                        });
                    return $q.when(tokenPromise);
                }
            };
        }
    ]).service('events', [
        '$rootScope', function($rootScope) {
            this.trigger = function(name, args) {
                if ($rootScope.$root.$$phase) {
                    //$rootScope.$broadcast(name, args);
                    $rootScope.$emit(name, args);
                } else {
                    $rootScope.$apply(function() {
                        //$rootScope.$broadcast(name, args);
                        $rootScope.$emit(name, args);
                    });
                }
            };

            this.on = function(name, handler) {
                return $rootScope.$on(name, handler);
            };
        }
    ]).factory('_', function() {
        return window._; // See FIXME in common-vendor-imports.js
    }).factory('momentDateTime', function() {
        return window.moment;
    });