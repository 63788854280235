(function () {
    'use strict';

    angular.module('bf.components.dashboard.user.activity', ['bf.constants']);

    angular.module('bf.components.dashboard.user.activity')
        .service('userDashboardActivity', [
            '_', '$timeout', '$q', 'momentDateTime', 'constants', 'dashboardService', 
            function (_, $timeout, $q, momentDateTime, constants, dashboardService) {

                function getMyDashboardActivity() {
                    return dashboardService.dashboardItems();
                }

                return {
                    getMyDashboardActivity: getMyDashboardActivity
                };
            }
        ])
        .directive('userDashboardActivityList', [
            'userDashboardActivity', 'constants', '_', '$state', 'loading', '$q', function (userDashboardActivity, constants, _, $state, loading, $q) {
                
                return {
                    restrict: 'E',
                    template: require('Site\\Components\\Views\\userDashboardActivityList.html'),
                    replace: true,
                    link: function ($scope, element, attrs) {

                        $scope.dashboardActivity = [];
                        $scope.noItems = false;

                        $scope.auditTracker = loading.createTracker(false, 1000);
                        var deferred = $q.defer();
                        userDashboardActivity.getMyDashboardActivity().then(function (myActivity) {
                            $scope.dashboardActivity = myActivity.map(function (item) {
                                item.localTime = moment.utc(item.CreatedDate).local().toDate();
                                return item;
                            });
                            $scope.progress.complete();
                            $scope.isLoading = false;
                            deferred.resolve(true);
                            if ($scope.dashboardActivity.length === 0) {
                                $scope.noItems = true;
                            }
                        });
                        $scope.auditTracker.track(deferred.promise);

                        $scope.$on('$destroy', function () {
                            unsubscribeAllEvents();
                        });

                        $scope.shouldArrowShow = function(data) {
                            if (data.ShortDesc.indexOf("uploaded") > -1 || data.Title.indexOf("Sign") > -1) {
                                return true;
                            }
                            return false;
                        }

                        $scope.gotoDocument = function(clientId, documentId) {
                            $state.go("documents", { workingClientId: clientId, documentId: documentId });
                        };

                        $scope.toggleSignatories = function(signatoriesScope) {
                            if (signatoriesScope.showSignatories) {
                                signatoriesScope.showSignatories = false;
                            } else {
                                signatoriesScope.showSignatories = true;
                            }
                        };

                        $scope.whereShouldIGo = function(data) {
                            if (data.ShortDesc.indexOf("uploaded") > -1 || data.Title.indexOf("Sign") > -1) {
                                $scope.gotoDocument(data.Client.ID, data.ObjectGuid);
                            }
                        };
                    }
                };

                function unsubscribeAllEvents() {
                }

            }
        ]);
})();