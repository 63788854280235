(function() {
    'use strict';

    var module = angular.module('app');

    module.service('announcementsService', [
        '$q', '_', 'constants', 'dataStoreService', 'ENVIRONMENTAL', function($q, _, constants, dataStoreService, ENVIRONMENTAL) {
            var apiSection = "/announcements/";

            function get(pageNumber, itemsPerPage) {
                var search = '';
                var pre = '?';

                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    search += pre + 'pageNumber=' + pageNumber + '&itemsPerPage=' + itemsPerPage;
                    pre = '&';
                }

                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'list/' + search);
            }

            function getById(id) {
                var deferred = $q.defer();

                if (!_.isUndefined(id)) {
                    return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + '/' + id);
                }

                deferred.reject();
                return deferred.promise;

            }

            return {
                get: get,
                getById: getById
            }

        }
    ]);
})();