(function () {
    "use strict";

    var appModule = angular.module("app");

    appModule.controller("DocumentsClientSelectCtrl", [
        "$scope", "$modalInstance", "constants", "clients", function ($scope, $modalInstance, constants, clients) {

            $scope.vm = {};
            $scope.vm.warning = "Cannot upload files to all clients.";
            $scope.title = "Select Client";
            $scope.message = "A Client selection is required before Upload can proceed.";
            $scope.confirmBtnText = "Upload";
            $scope.cancelBtnText = "Cancel";

            $scope.setClient = function (client) {
                $scope.selectedClient = client;
                $scope.clientId = client.Id;

                $scope.allClientsSelected = ($scope.clientId === "00000000-0000-0000-0000-000000000000");
            }

            $scope.clients = clients;
            $scope.setClient($scope.clients[0]);

            $scope.confirm = function () {
                $modalInstance.close($scope.clientId);
            }

            $scope.cancel = function () {
                $modalInstance.dismiss("cancel");
            }
        }
    ]);
})();