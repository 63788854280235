angular.module('app')

.controller('AcceptInviteCtrl', ['$scope', '$location', '$window', 'dataStoreService', '_', '$state', 'accountsService', 
    function ($scope, $location, $window, dataStoreService, _, $state, accountsService) {
    $scope.showPassword = false;
    $scope.showConfirmPassword = false;
    $scope.satisfied = false;
    $scope.expired = false;
    $scope.expiredWarning = "The invitation you are trying to accept has expired, click here to have a new one sent to your corresponding email address.";
    $scope.inviteSent = false;
    $scope.initialLoading = true;
    $scope.notFound = false;
    $scope.notFoundWarning = "Sorry, the invitation you are trying to accept was not found on the system.";
    $scope.userAlreadyExists = false;
    $scope.existingUserEmail = '';

    $scope.register = function (form) {
        if (_.isUndefined($state.params.inviteToken)) {
            alert("You have not been authorized to create an account");
            $state.go("login");
        } else {
            if ($scope.password == $scope.confirmPassword) {
                if (!$scope.satisfied) {
                    $scope.$parent.showNotification("Invalid", "please provide a password that satisfies the requirements", "error");
                    return;
                }
                $scope.progress.start();
                dataStoreService.acceptUserInvite($state.params.inviteToken, $scope.password).then(function (data) {
                    if (data.Message == "false" || data.Message == "An error has occurred.") {
                        alert("An error occurred on the server while trying to create your account, please try again. If the error persists please contact support");
                    } else if (data.Message == "expired") {
                        alert("The invite token you are using has expired, please contact your firm administrator to get a new one.");
                    } else {
                        $scope.$parent.showNotification("Success", "You have finished setting up your account and may now log in", "success");
                        $state.go('login');
                    }
                });
            } else {
                alert("Passwords do not match");
            }
        }
    }
    
    $scope.resend = function () {
        accountsService.resendInviteByToken($state.params.inviteToken).then(function (data) {
            $scope.inviteSent = true;
        }, function (err) {
            $scope.inviteSend = false;
        });
    }

    var activate = function () {
        if (!_.isUndefined($state.params.inviteToken)) {
            dataStoreService.loadUserInvite($state.params.inviteToken).then(function (data) {
                $scope.initialLoading = false;
                $scope.isLoading = false;
                
                if(data.userAlreadyExists)
                {
                    $scope.userAlreadyExists = data.userAlreadyExists;
                    $scope.existingUserEmail = data.existingUserEmail;
                }
                $scope.progress.complete();
            },
            function(err) {
                if (err === 404) {
                    $scope.notFound = true;
                } else if (err === 400) {
                    $scope.expired = true;
                }

                $scope.initialLoading = false;
                $scope.isLoading = false;
                $scope.progress.complete();
            });
        }

    }

    activate();

}]);