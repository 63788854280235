(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('DocumentsFilterDetailsCtrl', [
        '$scope', '$modalInstance', 'constants', '_', 'documentFiltersService', 'actionMode', 'passedData', function ($scope, $modalInstance, constants, _, documentFiltersService, actionMode, passedData) {

            $scope.confirmBtnText = 'Confirm';
            $scope.cancelBtnText = 'Cancel';
            $scope.canEdit = false;
            $scope.title = "";
            $scope.message = "";
            $scope.text = "";
            $scope.id = "";
            $scope.document = {};
            $scope.parentId = "";

            $scope.validationWarn = false;
            $scope.validationWarnMsg = "Required: Please supply a name for the filter";

            var validateFilterName = function () {
                if (_.isUndefined($scope.text) || $scope.text == "") {
                    return false;
                }
                return true;
            }

            $scope.treeViewOptions = {
                dataSource: new kendo.data.HierarchicalDataSource({
                    transport: {
                        read: function (options) {
                            options.success([{ Text: "Filters", Items: $scope.filters }]);
                        }
                    },
                    schema: {
                        parse: function (response) {
                            return $.map(response, function (x) {
                                x.expanded = x.Items && x.Items.length > 0;
                                return x;
                            });
                        },
                        model: {
                            id: "Id",
                            children: "Items",
                            hasChildren: function (item) {
                                return item.Items && item.Items.length > 0;
                            }
                        }
                    }
                }),
                dataBound: function (e) {
                    if (e.node) {
                        this.expand(e.node);
                    }
                },
                dataTextField: "Text",
                loadOnDemand: false
            };

            $scope.treeitemChange = function (dataItem) {
                $scope.selectedFilter = dataItem;
                $scope.filterId = dataItem.Id;
                $scope.disableFilterActions = dataItem.Text === 'Filters';
                $scope.warn = false;
                if (_.isUndefined($scope.selectedFilter.Id)) {
                    $scope.warn = true;
                    $scope.warning = "Selecting this filter will remove any existing filter from the document.";
                }
            }

            switch (actionMode) {
                case constants.FOLDERS.MODALMODES.CREATE:
                    $scope.title = 'Create New Filter';
                    $scope.message = 'Please enter the name of the new filter';
                    $scope.warn = false;
                    $scope.confirmBtnText = 'Create';
                    $scope.canEdit = true;
                    if (passedData) {
                        $scope.parentId = passedData.Id;
                    }
                    break;
                case constants.FOLDERS.MODALMODES.DELETE:
                    $scope.title = 'Delete Filter';
                    $scope.message = 'Please confirm you wish to delete this filter';
                    $scope.warn = true;
                    $scope.warning = "Documents assigned to this filter will be re-assigned with no filter.";
                    $scope.confirmBtnText = 'Delete';
                    $scope.canEdit = false;
                    if (passedData) {
                        $scope.text = passedData.Text;
                        $scope.id = passedData.Id;
                    }
                    break;
                case constants.FOLDERS.MODALMODES.EDIT:
                    $scope.title = 'Update Filter';
                    $scope.message = 'Please change the settings below';
                    $scope.warn = false;
                    $scope.confirmBtnText = 'Update';
                    $scope.canEdit = true;
                    if (passedData) {
                        $scope.text = passedData.Text;
                        $scope.id = passedData.Id;
                    }
                    break;
                case constants.FOLDERS.MODALMODES.ASSIGN:
                    $scope.title = 'Assign Filter';
                    $scope.message = 'Please select a filter for the document:';
                    $scope.docTitle = passedData.doc.Title;
                    $scope.warn = false;
                    $scope.confirmBtnText = 'Assign';
                    $scope.canEdit = true;
                    $scope.assign = true;
                    if (passedData) {
                        $scope.filters = passedData.filters;
                        $scope.id = passedData.doc.Id;
                        $scope.document = passedData.doc;
                    }
                    break;
                case constants.FOLDERS.MODALMODES.MULTIASSIGN:
                    $scope.title = 'Assign Filter to Multiple Documents';
                    $scope.message = 'Please select a filter for the documents.';
                    $scope.warn = false;
                    $scope.confirmBtnText = 'Assign';
                    $scope.canEdit = true;
                    $scope.assign = true;
                    if (passedData) {
                        $scope.filters = passedData;
                    }
                    break;
                default:
                    $scope.title = 'Opps';
                    $scope.message = 'Something didn\'t work out';
                    break;
            }

            $scope.confirm = function () {
                var returnVal = false;
                switch (actionMode) {
                    case constants.FOLDERS.MODALMODES.CREATE:
                        if (!validateFilterName()) {
                            $scope.validationWarn = true;
                            return;
                        }
                        returnVal = { success: true, text: $scope.text, parentId: $scope.parentId };
                        break;
                    case constants.FOLDERS.MODALMODES.DELETE:
                        returnVal = { success: true, id: $scope.id };
                        break;
                    case constants.FOLDERS.MODALMODES.EDIT:
                        if (!validateFilterName()) {
                            $scope.validationWarn = true;
                            return;
                        }
                        returnVal = { success: true, id: $scope.id, text: $scope.text };
                        break;
                    case constants.FOLDERS.MODALMODES.ASSIGN:
                        if (!_.isUndefined($scope.selectedFilter)) {
                            if ($scope.document.DocumentFilter == null) {
                                if (!_.isUndefined($scope.selectedFilter.Id)) {
                                    returnVal = { success: true, id: $scope.id, filter: $scope.selectedFilter };
                                } else {
                                    $scope.warn = true;
                                    $scope.warning = "Invalid filter, choose another one please.";
                                    return;
                                }
                            } else {
                                if ($scope.selectedFilter.Id != $scope.document.DocumentFilter.Id) {
                                    returnVal = { success: true, id: $scope.id, filter: $scope.selectedFilter };
                                } else {
                                    $scope.warn = true;
                                    $scope.warning = "The document: " + $scope.document.Title + " is already assigned to this filter.";
                                    return;
                                }
                            }
                        }
                        break;
                    case constants.FOLDERS.MODALMODES.MULTIASSIGN:
                        if (!_.isUndefined($scope.selectedFilter)) {
                            if (!_.isUndefined($scope.selectedFilter.Id)) {
                                returnVal = { success: true, id: $scope.id, filter: $scope.selectedFilter };
                            } else {
                                $scope.warn = true;
                                $scope.warning = "Invalid filter, choose another one please.";
                                return;
                            }
                        }
                        break;
                    default:

                        break;
                }
                $modalInstance.close(returnVal);
            }

            $scope.cancel = function() {
                $modalInstance.dismiss('cancel');
            }

        }
    ]);
})();