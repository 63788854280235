angular.module('app')

    // Path: /account/login
    .controller('LoginCtrl',
        ['auth', '$scope', '$state', 'events', 'constants', 'store', 'ENVIRONMENTAL', '$location', '$rootScope', '$stateParams', 'brandingService', '$window',
            function (auth, $scope, $state, events, constants, store, ENVIRONMENTAL, $location, $rootScope, $stateParams, brandingService, $window) {
                $scope.$parent.hideNotification();
                $scope.progress.start();
                $scope.loading = true;
           
                var theme = getParameterByName('theme');
                if (theme && theme !== null) {
                    store.set('theme', theme);
                }

                auth.signin({
                    identityDomain: ENVIRONMENTAL.AUTH0.DOMAIN,
                    scope: 'openid profile cp-api'
                }, onLoginSuccess, onLoginFailed);

                function onLoginFailed(errorMessage) { }
                function onLoginSuccess(errorMessage) { }

                function getParameterByName(name) {
                    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
                    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
                }
            }]);
