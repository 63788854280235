(function () {
    'use strict';

    var module = angular.module('bf.services.data', []);

    module.service('dataStoreService', [
        '$q', '_', '$http', 'constants', 'ENVIRONMENTAL', function ($q, _, $http, constants, ENVIRONMENTAL) {


            function localReadClientsFromStore() {
                var deffered = $q.defer();
                getResourceData(ENVIRONMENTAL.BASEURLS.API + "/clients").then(function (theData) {
                    deffered.resolve(theData);
                });
                return deffered.promise;
            }

            function loadUserInviteDetails(token) {
                var deffered = $q.defer();
                getResourceData(ENVIRONMENTAL.BASEURLS.API + "/accounts/loadInvite/" + token).then(function (theData) {
                    deffered.resolve(theData);
                }, function (err) {
                    deffered.reject(err);
                });
                return deffered.promise;
            }

            function acceptUserInvite(token, password) {
                var deffered = $q.defer();
                var dataToSend = { inviteToken: token, password: password };

                postResourceData(ENVIRONMENTAL.BASEURLS.API + "/accounts/acceptInvite", dataToSend).then(function (theData) {
                    deffered.resolve(theData);
                });
                return deffered.promise;
            }

            function requestPassword(email) {
                var deffered = $q.defer();
                postResourceData(ENVIRONMENTAL.BASEURLS.API + "/accounts/requestPasswordReset", email).then(function (theData) {
                    deffered.resolve(theData);
                }, function (err) { 
                    deffered.reject(err);
                });
                return deffered.promise;
            }
            function resetPassword(data) {
                var deffered = $q.defer();
                postResourceData(ENVIRONMENTAL.BASEURLS.API + "/accounts/performPasswordReset", data).then(function (theData) {
                    deffered.resolve(theData);
                }, function (err) { 
                    deffered.reject(err);
                });
                return deffered.promise;
            }
            function getResourceData(uri) {
                var deffered = $q.defer();
                $http({
                    url: uri,
                    method: "GET"
                }).success(function (data, status, headers, config) {
                    deffered.resolve(data);
                }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            function putResourceData(uri, theDataToStore) {
                var deffered = $q.defer();

                var dataToSend = JSON.stringify(theDataToStore);

                $http({
                    url: uri,
                    method: "PUT",
                    data: dataToSend,
                    headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                }).success(function (data, status, headers, config) {
                    deffered.resolve(data);
                }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            function deleteResourceData(uri, theDataToRemove) {
                var deffered = $q.defer();

                var dataToSend = JSON.stringify(theDataToRemove);

                $http({
                    url: uri,
                    method: "DELETE",
                    data: dataToSend,
                    headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                }).success(function (data, status, headers, config) {
                    deffered.resolve(data);
                }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            // Refactor Updating from put to post
            function postResourceData(uri, theDataToStore) {
                var deffered = $q.defer();

                var dataToSend = JSON.stringify(theDataToStore);

                $http({
                    url: uri,
                    method: "POST",
                    data: dataToSend,
                    headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                }).success(function (data, status, headers, config) {
                    deffered.resolve(data);
                }).error(function (data, status, headers, config) {
                    deffered.reject(status);
                });
                return deffered.promise;
            }

            return {
                readClientsFromStore: localReadClientsFromStore,

                requestPasswordReset: requestPassword,
                resetPassword: resetPassword,

                loadUserInvite: loadUserInviteDetails,
                acceptUserInvite: acceptUserInvite,

                getResource: getResourceData,
                addResource: putResourceData,
                deleteResource: deleteResourceData,
                updateResource: postResourceData

            }
        }
    ]);
})();