(function() {
    "use strict";

    var appModule = angular.module("app");

    appModule.controller("DocumentsCtrl", [
        "$scope", "$state", "$stateParams", "documentsService", "_", "documentFiltersService", "events", "constants", "$q", "$modal", "$timeout", "momentDateTime", "uploadService", "downloadService", "authHelper", "accountsService", "store", "systemSettingsService", "jwtHelper", "ENVIRONMENTAL", "logService",
        function ($scope, $state, $stateParams, documentsService, _, documentFiltersService, events, constants, $q, $modal, $timeout, momentDateTime, uploadService, downloadService, authHelper, accountsService, store, systemSettingsService, jwtHelper, env, logService) {

            $scope.clientId = !_.isUndefined($state.params.workingClientId) ? $state.params.workingClientId : "";
            $scope.documentId = !_.isUndefined($state.params.documentId) ? $state.params.documentId : "";
            $scope.shouldShowSigningWindow = !_.isUndefined($state.current.data.sign) ? $state.current.data.sign : false;
            $scope.shouldShowPreviewWindow = !_.isUndefined($state.current.data.preview) ? $state.current.data.preview : false;
            $scope.filterId = null;
            $scope.selectedFilter = null;
            $scope.searchTerm = "";
            $scope.searchForm = {};
            $scope.isSearching = true;
            $scope.uploadProgressResults = [];
            $scope.targetZoneVisible = false;
            $scope.dragOverError = false;
            $scope.dragOverErrorMessage = "";
            $scope.isAccountEligibleToSign = false;
            $scope.disableFilterActions = true;
            var initialDataLoad = true;
            $scope.isAdobeSignableTask = false;
            $scope.confirmInProgress = false;
            $scope.isMultiSelect = false;
            $scope.selectedFile = false;
            var fileApiToken = store.get("tokenFiles");
            $scope.previousSelection = null;
            $scope.showSelectedFilter = false;
            $scope.showHelpMessage = false;

            $scope.pageSize = 10;
            $scope.updatePageSize = function () {
                $("#docGrid").data("kendoGrid").dataSource.pageSize($scope.pageSize);
            };

            if ($scope.shouldShowSigningWindow === true && !_.isUndefined($scope.documentId) && $scope.documentId !== "") {
                documentsService.getById($stateParams.documentId).then(function (documentDetails) {
                    if (documentDetails !== "" && !_.isUndefined(documentDetails.Id) && !_.isUndefined(documentDetails.CreatedDate)) {
                        $scope.selectedFileDataItem = documentDetails;
                        $scope.signDocument();
                    } else {
                        $scope.$parent.showNotification("Error", "Requested document not found. Please check the link you used is correct.", "error");
                    }
                }, function(error) {
                    $scope.$parent.showNotification("Error", "Failed to get document details, please try again.", "error");
                    logService.error(error, "Get Document Details Error for Document Id " + $stateParams.documentId);
                });
            }

            if ($scope.shouldShowPreviewWindow === true && !_.isUndefined($scope.documentId) && $scope.documentId !== "") {
                documentsService.getById($stateParams.documentId).then(function(documentDetails) {
                    if (documentDetails !== "" && !_.isUndefined(documentDetails.Id) && !_.isUndefined(documentDetails.CreatedDate)) {
                        var fileExtToPreview = documentDetails.Extension;
                        isFileExtPreviewable(fileExtToPreview).then(function(result) {
                            if (result && result === true) {
                                $scope.selectedFileDataItem = documentDetails;
                                $scope.previewDocument();
                            } else {
                                $scope.$parent.showNotification("Error", "The document you have requested to preview is not a supported file type.", "error");
                            }
                        });
                    } else {
                        $scope.$parent.showNotification("Error", "Requested document not found. Please check the link you used is correct.", "error");
                    }
                }, function(error) {
                    $scope.$parent.showNotification("Error", "Failed to get document details, please try again.", "error");
                    logService.error(error, "Get Document Details Error for Document Id " + $stateParams.documentId);
                });
            }


            if (_.isUndefined(fileApiToken) || _.isNull(fileApiToken)) {
                authHelper.getDelegatedToken(authHelper.getFileApiDelegationOptions()).then(function (token) {
                    fileApiToken = token;
                    store.set("tokenFiles", token);
                });
            } else {
                if (jwtHelper.isTokenExpired(fileApiToken)) {
                    authHelper.getDelegatedToken(authHelper.getFileApiDelegationOptions()).then(function (token) {
                        fileApiToken = token;
                        store.set("tokenFiles", token);
                    });
                }
            }

            var getAuthObj = function () {
                return {
                    'Authorization':
                        "Bearer " + fileApiToken
                };
            };

            $scope.getUploadConfig = {
                headers: getAuthObj
            };

            $scope.showTargetZone = function () {
                $scope.targetZoneVisible = true;
                $scope.dragOverError = false;
                if ($scope.allClientsSelected === true) {
                    $scope.dragOverErrorMessage = "Upload will require you to select a client to upload to.";
                } else {
                    $scope.dragOverErrorMessage = "";
                }
            };

            $scope.hideTargetZone = function () {
                $scope.targetZoneVisible = false;
                $scope.dragOverError = false;
            };

            $scope.deleteDocument = function () {
                var documentIsOwnedByUser = function(item, profile) {
                    return item.CreatedById.toUpperCase() === profile.userId.toUpperCase();
                };

                var mode;
                var selectedCount = 1;
                var notOwnedByCount = 0;
                var profile = store.get("profile");
                if (_.isArray($scope.selectedFileDataItem)) {
                    mode = constants.FOLDERS.MODALMODES.DELETEMULTI;
                    selectedCount = $scope.selectedFileDataItem.length;
                    $scope.selectedFileDataItem.forEach(function (item, index) {
                        if (!documentIsOwnedByUser(item, profile)) {
                            notOwnedByCount++;
                        }
                    });
                } else {
                    mode = constants.FOLDERS.MODALMODES.DELETE;
                }

                var modalInstance = $modal.open({
                    template: require('Documents\\DocumentsDelete.html'),
                    controller: "DocumentsDeleteCtrl",
                    resolve: {
                        file: function() {
                            return $scope.selectedFileDataItem;
                        },
                        actionMode: function () {
                            return mode;
                        },
                        selectedCount: function() {
                            return selectedCount;
                        },
                        notOwnedByCount: function() {
                            return notOwnedByCount;
                        }
                    }
                });

                modalInstance.result.then(function(result) {
                    if (result) {
                        var selectedDocs;
                        if (!$scope.selectedFileDataItem) {
                            selectedDocs = [];
                        } else if (_.isArray($scope.selectedFileDataItem)) {
                            selectedDocs = $scope.selectedFileDataItem;
                        } else {
                            selectedDocs = [$scope.selectedFileDataItem];
                        }

                        var itemIds = selectedDocs.filter(function(item) {
                            return documentIsOwnedByUser(item, profile)
                        }).map(function(item) {
                            return item.Id;
                        });

                        var getDocument = function(id) {
                            for (var i=0; i<selectedDocs.length; i++) {
                                if (selectedDocs[i].Id === id) {
                                    return selectedDocs[i];
                                }
                            }

                            return undefined;
                        }

                        documentsService.removeMultiple(itemIds).then(function(deletionResults) {
                            var documentsWithOutstandingTasks = [];
                            var documentsWithOtherFailures = [];

                            for (var i=0; i <deletionResults.length; i++) {
                                var deletionResult = deletionResults[i];

                                if (deletionResult.Result === "outstandingsigntasks") {
                                    documentsWithOutstandingTasks.push(getDocument(deletionResult.DocumentId));
                                } else if (deletionResult.Result === "notfound") {
                                    continue; // Another user has since successfully deleted the document
                                } else if (deletionResult.Result) {
                                    documentsWithOtherFailures.push(getDocument(deletionResult.DocumentId));
                                }
                            }

                            if (documentsWithOtherFailures.length > 0 || documentsWithOutstandingTasks.length > 0) {
                                var modalInstance = $modal.open({
                                    template: require('documents\\DocumentsFailedToDelete.html'),
                                    controller: function ($scope, $modalInstance, documentsWithOutstandingTasks, documentsWithOtherFailures) {
                                        $scope.confirm = function() {
                                            $modalInstance.close();
                                        };

                                        $scope.documentsWithOutstandingTasks = documentsWithOutstandingTasks;
                                        $scope.documentsWithOtherFailures = documentsWithOtherFailures;
                                    },
                                    resolve: {
                                        documentsWithOutstandingTasks: function() {
                                            return documentsWithOutstandingTasks
                                        },
                                        documentsWithOtherFailures: function() {
                                            return documentsWithOtherFailures;
                                        }
                                    },
                                    backdrop: true
                                });

                                return modalInstance.result;
                            }
                        })
                        .catch(function (rejection) {
                            // 500 error, can't contact server etc
                            $scope.showNotification("Error", "Document deletion failed, please try again.", "error");
                            logService.error(rejection.status, "Document delete error");
                            return $q.reject(rejection.status);
                        })
                        .finally(function () {
                            clearDeepLinkedDocument();
                            refreshGrid();
                            $scope.progress.complete();
                        });
                    }
                }, function() {

                });
            }

            $scope.downloadDocument = function () {
                if (_.isArray($scope.selectedFileDataItem)) {
                    $scope.selectedFileDataItem.forEach(function (item, index) {
                        var fileIdToDownload = item.Id;
                        $scope.progress.start();
                        downloadService.downloadFile(fileIdToDownload, item.Title).then(function () {
                            $scope.progress.complete();
                        }, function (failureReason) {
                            $scope.$parent.showNotification("Download Error", "Failed to initialize download, please try again.", "error");
                            logService.error(failureReason, "Download Error for Document Id " + fileIdToDownload);
                        });
                    });
                } else {
                    var fileIdToDownload = $scope.selectedFileDataItem.Id;
                    $scope.progress.start();
                    downloadService.downloadFile(fileIdToDownload, $scope.selectedFileDataItem.Title).then(function () {
                        $scope.progress.complete();
                    }, function (failureReason) {
                        $scope.$parent.showNotification("Download Error", "Failed to initialize download, please try again.", "error");
                        logService.error(failureReason, "Download Error for Document Id " + fileIdToDownload);
                    });
                }
            }

            function isFileExtPreviewable(fileExt) {
                return systemSettingsService.getPreviewableFileExtensions().then(function (extensions) {
                    if (_.indexOf(extensions, fileExt.toUpperCase()) !== -1) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }

            $scope.isSelectedDocumentPreviewable = function () {
                var fileExtToPreview = $scope.selectedFileDataItem.Extension;
                isFileExtPreviewable(fileExtToPreview).then(function(result) {
                    $scope.canPreviewSelectedDocument = result;
                }, function(error) {
                    $scope.canPreviewSelectedDocument = false;
                });
            }

            function toHex(n) {
                n = parseInt(n, 10);
                if (isNaN(n)) return "00";
                n = Math.max(0, Math.min(n, 255));
                return "0123456789ABCDEF".charAt((n - n % 16) / 16)
                     + "0123456789ABCDEF".charAt(n % 16);
            }

            $scope.previewDocument = function () {
                var fileIdToPreview = $scope.selectedFileDataItem.Id;
                var fileTitle = $scope.selectedFileDataItem.Title;
                var branding = $("header.row").css("background-color");
                if (branding.length > 6 && branding.substr(0,3)==="rgb") {
                    var rgb = branding.replace(/[^\d,.]/g, "").split(",");
                    branding = toHex(rgb[0]) + toHex(rgb[1]) + toHex(rgb[2]);
                }
                $modal.open({
                    template: require('Documents\\DocumentsPreview.html'),
                    controller: "DocumentsPreviewCtrl",
                    resolve: {
                        fileId: function () {
                            return fileIdToPreview;
                        },
                        fileTitle: function () {
                            return fileTitle;
                        },
                        storagePath: function() {
                            return null;
                        },
                        brandingColour: function() {
                            return branding;
                        }
                    },
                    size: "lg"
                });
            }

            $scope.signDocument = function () {
                var doc = $scope.selectedFileDataItem;
                switch (doc.ActionTypeE) {
                    case constants.DOCUMENTS.ACTIONTYPES.ADOBESIGN:
                    case constants.DOCUMENTS.ACTIONTYPES.ADOBESECURESIGN:
                        captureCurrentLocationAndNavigateToAdobeSign(doc.Id);
                        break;
                    case constants.DOCUMENTS.ACTIONTYPES.SIGN:
                        $modal.open({
                            template: require('Documents\\DocumentsPrepareSign.html'),
                            controller: "DocumentsPrepareSignCtrl",
                            resolve: {
                                selectedDoc: function () {
                                    return doc;
                                }
                            },
                            size: "lg",
                            backdrop: "static"
                        });
                        break;
                    case constants.DOCUMENTS.ACTIONTYPES.SECURESIGN:
                        $modal.open({
                            template: require('Documents\\MultipleSignatures\\VerifyPinBeforeSigningModal.html'),
                            controller: "VerifyPinCtrl",
                            resolve: {
                                selectedDoc: function () {
                                    return doc;
                                },
                                renew: function () {
                                    return false;
                                }
                            },
                            size: "lg"
                        });
                        break;
                    case constants.DOCUMENTS.ACTIONTYPES.CLICKTOSIGN:
                        $modal.open({
                            template: require('Documents\\MultipleSignatures\\VerifyPinBeforeSigningModal.html'),
                            controller: "VerifyPinCtrl",
                            resolve: {
                                selectedDoc: function () {
                                    return doc;
                                },
                                renew: function () {
                                    return false;
                                }
                            },
                            size: "lg"
                        });
                        break;
                    default:
                        $scope.showNotification("No Signing Task!", "This document has no signing task", "error");
                        break;
                }
            }

            function captureCurrentLocationAndNavigateToAdobeSign(docId) {
                $scope.progress.reset();
                $scope.progress.start();

                documentsService.getAdobeSignSigningUrl(docId)
                    .then(function (adobeSignUrl) {
                        if (adobeSignUrl === '') {
                        }
                        else {
                            // Capture our current location so that we come back here after signing is complete
                            var locCaptureUrl = env.BASEURLS.API + "/api/adobesign/agreements/setreturn?url=" + encodeURIComponent(window.location);
                            var i = document.createElement("iframe");
                            i.style.display = "none";
                            i.onload = function () {
                                window.location = adobeSignUrl;
                            };
                            i.src = locCaptureUrl;
                            document.body.appendChild(i);
                        }
                    })
                    .catch(function(err) {
                        // Do nothing - this seems to be what the user portal does when signing is not valid!
                    })
                    .finally(function() {
                        $scope.progress.complete();
                    });
            }

            $scope.treeViewOptions = {
                dataSource: new kendo.data.HierarchicalDataSource({
                    transport: {
                        read: function(options) {
                            if (_.isUndefined($scope.clientId) || _.isEmpty($scope.clientId)) {
                                options.error(null);
                                return;
                            }
                            documentFiltersService.get($scope.clientId)
                                .then(function(data) {
                                    options.success([{ Text: "Filters", Items: data }]);
                                    $scope.isLoading = false;
                                    $scope.progress.complete();
                                }, function() {
                                    $scope.isLoading = false;
                                    $scope.progress.complete();
                                });
                        }
                    },
                    schema: {
                        parse: function (response) {
                            return $.map(response, function (x) {
                                x.expanded = ($(window).width() > 768) && (x.Items && x.Items.length > 0);
                                return x;
                            });
                        },
                        model: {
                            id: "Id",
                            children: "Items",
                            hasChildren: function(item) {
                                return item.Items && item.Items.length > 0;
                            }
        }
                    }
                }),
                dataBound: function (e) {
                    if ($(window).width() > 768) {
                        if (e.node) {
                            this.expand(e.node);
                        }
                    }
                },
                dataTextField: "Text",
                loadOnDemand: false
            };

            $scope.searchForm.handleSearch = function () {
                clearDeepLinkedDocument();
                removeCachedSelection();
                $scope.isSearching = true;
                events.trigger(constants.DOCUMENTS.EVENTS.SEARCH, $scope.searchForm.term);
            };

            var searchEvt = events.on(constants.DOCUMENTS.EVENTS.SEARCH, function (evt, searchTerm) {
                $scope.searchTerm = searchTerm;
                refreshGrid();
            });

            var refreshRequired = events.on(constants.DOCUMENTS.EVENTS.REFRESHREQUIRED, function () {
                $scope.isSearching = true;
                refreshGrid();
            });

            $scope.$on("$destroy", function () {
                unsubscribeAllEvents();
            });

            var unsubscribeAllEvents = function () {
                searchEvt();
                refreshRequired();
            };

            $scope.handleChange = function (data) {
                var profile = store.get("profile");
                $scope.selectedFile = (!_.isUndefined(data) || !_.isNull(data));
                $scope.isAdobeSignableTask = false;
                if (data.length > 1) {
                    $scope.selectedFileDataItem = data;
                    $scope.canPreviewSelectedDocument = false;
                    $scope.isAccountEligibleToSign = false;
                    $scope.confirmInProgress = false;
                    $scope.isMultiSelect = true;
                    $scope.allowDelete = false;
                    var keepLooping = true;

                    data.forEach(function (item, index) {
                        if (keepLooping) {
                            // NOTE: We want to check if all selected items are not owned by the user
                            $scope.allowDelete = (item.CreatedById.toUpperCase() === profile.userId.toUpperCase());
                            keepLooping = ($scope.allowDelete === false);
                        }
                    });
                } else {
                    if (_.isUndefined(data.length)) {
                        $scope.selectedFileDataItem = data;
                    } else {
                        $scope.selectedFileDataItem = data[0];
                    }
                    $scope.allowDelete = ($scope.selectedFileDataItem.CreatedById.toUpperCase() === profile.userId.toUpperCase());
                    $scope.isSelectedDocumentPreviewable();
                    $scope.isMultiSelect = false;
                    setIsEligibleToSignClickToSign($scope.selectedFileDataItem);
                    setIsEligibleToSignAdobeSign($scope.selectedFileDataItem);
                }
                $scope.previousSelection = $scope.selectedFileDataItem;
            };

            function setIsEligibleToSignAdobeSign(doc) {
                if (doc.ActionTypeE !== constants.DOCUMENTS.ACTIONTYPES.ADOBESIGN &&
                    doc.ActionTypeE !== constants.DOCUMENTS.ACTIONTYPES.ADOBESECURESIGN) {
                    return;
                }

                $scope.confirmInProgress = true;
                documentsService.getIsEligibleForAdobeSign(doc.Id)
                    .then(function(isSignable) {
                        $scope.isAdobeSignableTask = isSignable;
                    })
                    .finally(function () {
                        $scope.confirmInProgress = false;
                    });
            }

            function setIsEligibleToSignClickToSign(doc) {
                if ($scope.selectedFileDataItem.ActionTypeE !== constants.DOCUMENTS.ACTIONTYPES.SIGN &&
                    $scope.selectedFileDataItem.ActionTypeE !== constants.DOCUMENTS.ACTIONTYPES.SECURESIGN &&
                    $scope.selectedFileDataItem.ActionTypeE !== constants.DOCUMENTS.ACTIONTYPES.CLICKTOSIGN) {
                    return;
                }

                $scope.confirmInProgress = true;
                documentsService.getIsEligibleForClickToSign(doc.Id)
                    .then(function (isSignable) {
                        $scope.isAccountEligibleToSign = isSignable;
                    })
                    .finally(function () {
                        $scope.confirmInProgress = false;
                    });
            }

            $scope.treeitemChange = function (dataItem) {
                $scope.showSelectedFilter = false;
                clearDeepLinkedDocument();
                removeCachedSelection();
                $scope.selectedFilter = dataItem;
                $scope.filterId = dataItem.Id;
                $scope.disableFilterActions = dataItem.Text === "Filters";
                if ($(window).width() < 768) {
                    $scope.tree.collapse(".k-item");
                    if (dataItem.Text !== "Filters") {
                        $scope.showSelectedFilter = true;
                    }
                }
                refreshGrid();
            }

            var createFilterModal = function (mode, dataToPass) {
                return $modal.open({
                    template: require('Documents\\DocumentsFilterDetails.html'),
                    controller: "DocumentsFilterDetailsCtrl",
                    resolve: {
                        actionMode: function () {
                            return mode;
                        },
                        passedData: function () {
                            return dataToPass;
                        }
                    }
                });
            };

            $scope.createNewFolder = function () {
                var modalInstance;
                if (!$scope.selectedFilter) {
                    modalInstance = createFilterModal(constants.FOLDERS.MODALMODES.CREATE, "");
                    modalInstance.result.then(function (result) {
                        if (result.success) {
                            $scope.progress.start();
                            documentFiltersService.add($scope.clientId, result.text).then(function () {
                                refreshTree();
                            });
                        }
                    });
                } else {
                    var filtersItem = $scope.tree.dataItem($scope.tree.findByText("Filters"));
                    var target = $scope.tree.findByUid($scope.selectedFilter.uid);
                    var targetItem = $scope.tree.dataItem(target);
                    var levelCount = 0;
                    while (targetItem != filtersItem) {
                        target = $scope.tree.parent(target);
                        targetItem = $scope.tree.dataItem(target);
                        levelCount++;
                        if (levelCount >= 3) break;
                    }
                    if (levelCount >= 3) {
                        alert("Filters are restricted to 3 levels");
                        return;
                    } else {

                        modalInstance = createFilterModal(constants.FOLDERS.MODALMODES.CREATE, $scope.selectedFilter);
                        modalInstance.result.then(function (result) {
                            if (result.success) {
                                $scope.progress.start();
                                documentFiltersService.add($scope.clientId, result.text, result.parentId).then(function () {
                                    refreshTree();
                                });
                            }
                        });
                    }
                }
            }

            $scope.renameFolder = function () {
                if (!$scope.selectedFilter) {
                    alert("Please select a filter before clicking rename");
                } else {
                    var modalInstance = createFilterModal(constants.FOLDERS.MODALMODES.EDIT, $scope.selectedFilter);
                    modalInstance.result.then(function (result) {
                        if (result.success) {
                            documentFiltersService.update(result.id, result.text).then(function (data) {
                                if (data.Message === "permission denied") {
                                    $scope.$parent.showNotification("Permission denied", "Cannot edit filters created by your firm", "error");
                                } else if (data === "success") {
                                    $scope.$parent.showNotification("Success", "Filter updated", "success");
                                    refreshTree();
                                } else {
                                    $scope.$parent.showNotification("Error", "Something went wrong, please try again", "error");
                                }
                            });
                        }

                    }, function () {

                    });
                }
            }

            $scope.deleteFolder = function () {
                if (!$scope.selectedFilter) {
                    alert("Please select a filter before clicking edit");
                } else {
                    var modalInstance = createFilterModal(constants.FOLDERS.MODALMODES.DELETE, $scope.selectedFilter);

                    modalInstance.result.then(function (result) {
                        if (result.success) {
                            documentFiltersService.remove(result.id).then(function (data) {
                                if (data.Message === "permission denied") {
                                    $scope.$parent.showNotification("Permission denied", "Cannot delete filters created by your firm", "error");
                                } else if (data === "success") {
                                    $scope.$parent.showNotification("Success", "Filter removed", "success");
                                    refreshTree();
                                } else {
                                    $scope.$parent.showNotification("Error", "Something went wrong, please try again", "error");
                                }
                            });
                        }
                    }, function () {

                    });
                }
            }

            $scope.assignFolder = function () {
                if (!$scope.selectedFileDataItem) {
                    alert("Please select a document before trying to assign filters.");
                } else {
                    if ($scope.selectedFileDataItem.length > 1) {
                        var documentIdList = [];
                        documentFiltersService.get($scope.clientId).then(function (result) {
                            var modalInstance = createFilterModal(constants.FOLDERS.MODALMODES.MULTIASSIGN, result);
                            modalInstance.result.then(function (result) {
                                if (result.success) {
                                    $scope.progress.start();
                                    for (var i = 0; i < $scope.selectedFileDataItem.length; i++) {
                                        var item = $scope.selectedFileDataItem[i];
                                        var sameFilter = false;
                                        if (typeof (item.DocumentFilter) != "undefined" && item.DocumentFilter != null) {
                                            sameFilter = (result.filter.Id == item.DocumentFilter.Id);
                                        }
                                        if (!sameFilter) {
                                            documentIdList.push(item.Id);
                                        }
                                    }
                                    if (documentIdList.length > 0) {
                                        documentIdList.forEach(function (documentId, index) {
                                            documentsService.assignFilter(documentId, result.filter.Id).then(function () {
                                                if (index === documentIdList.length - 1) {
                                                    refreshTree();
                                                    refreshGrid();
                                                }
                                            }
										);
                                        }
										);
                                    }
                                }
                            }, function () {

                            });
                        });
                    } else {
                        documentFiltersService.get($scope.clientId).then(function (result) {
                            var modalInstance = createFilterModal(constants.FOLDERS.MODALMODES.ASSIGN, { doc: $scope.selectedFileDataItem, filters: result });
                            modalInstance.result.then(function (result) {
                                if (result.success) {
                                    documentsService.assignFilter(result.id, result.filter.Id).then(function () {
                                        refreshTree();
                                        refreshGrid();
                                    }, function (err) {
                                        console.error("Error received:", err);
                                    });
                                }
                            }, function () {

                            });
                        });
                    }
                }
            }

            $scope.fileUploadProgress = function (flowFile) {
                uploadService.updateProgress(flowFile.Id, Math.floor(flowFile.progress() * 100));
            }

            $scope.fileUploadSuccess = function (flowFile) {
                uploadService.commitFile(flowFile.Id)
                    .then(function () {
                        return documentsService.add($scope.clientId, getFileMetaData(flowFile));
                    })
                    .then(function (result) {
                    if (result.Message == "bad move" || result.Message == "no client" || result.Message == "An error has occurred.") {
                        uploadService.errored(flowFile.Id);
                    } else {
                        uploadService.completed(flowFile.Id);
                        refreshGrid();
                    }
                    })
                    .catch(function () {
                    uploadService.errored(flowFile.Id);
                });
            }

            $scope.fileUploadError = function (flowFile) {
                uploadService.errored(flowFile.Id);
            }

            $scope.fileUploadRetrying = function (flowFile) {
                uploadService.retrying(flowFile.Id);
            }

            var uploadFiles = function (files) {
                if (files.length > 0) {
                    _.each(files, function (file) {
                        file.folderId = $scope.filterId;
                    });
                    uploadService.addFiles(files).then(function () {
                        uploadService.startAllUploads();
                    }, function (reason) {
                        console.log(reason);
                    });
                }
            }

            $scope.onFileSelect = function (files) {
                if ($scope.allClientsSelected === true) {
                    $modal.open({
                            template: require('Documents\\DocumentsClientSelect.html'),
                            controller: "DocumentsClientSelectCtrl",
                            resolve: {
                                clients: function() {
                                    return $scope.clients;
                                }
                            }
                        }).result
                        .then(function(result) {
                            if (result && result.length > 0 && result != "00000000-0000-0000-0000-000000000000") {
                                $state.params.workingClientId = result;
                                setClient();
                                uploadFiles(files);
                            }
                        })
                        .catch(function(error) {

                        });
                } else {
                    uploadFiles(files);
                }
            }

            var getFileMetaData = function (flowFile) {
                return {
                    name: flowFile.file.name,
                    size: flowFile.file.size,
                    uploadIdentifier: flowFile.Identifier,
                    folderId: flowFile.folderId
                };
            }

            $scope.filterClient = function (client) {
                clearDeepLinkedDocument();
                $scope.showSelectedFilter = false;
                removeCachedSelection();
                $scope.progress.start();
                $scope.clientId = client.Id;
                $scope.selectedClient = client;
                $scope.allClientsSelected = ($scope.clientId === "00000000-0000-0000-0000-000000000000");
                refreshTree();
                refreshGrid();

            }

            var getClientsList = function () {
                var defer = $q.defer();

                accountsService.getById($scope.auth.profile.userId).then(function (account) {
                    defer.resolve(_.sortBy(account.Clients, function(i) { return i.Name.toLowerCase(); }));
                });

                return defer.promise;
            }

            var displayNoResultsMessageIfNeeded = function (grid) {
                // Get the number of Columns in the grid
                var dataSource = grid.data("kendoGrid").dataSource;
                var colCount = grid.find(".k-grid-header colgroup > col").length;

                // If there are no results place an indicator row
                if (dataSource._view.length == 0) {
                    grid.find(".k-grid-content tbody")
                        .append('<tr role="row" class="no-results-row"><td colspan="' + colCount + '" style="text-align:center"><b>No Results Found!</b></td></tr>');
                }
            }


            $scope.mainGridOptions = {
                dataSource: {
                    transport: {
                        read: function(options) {
                            if (!_.isUndefined($state.params.documentId) && $state.params.documentId != "" && !_.isNull($state.params.documentId)) {
                                var promise = documentsService.getById($stateParams.documentId)
                                    .then(function(data) {
                                        if(_.isUndefined(data.Id) && _.isUndefined(data.CreatedDate) || data === ""){
                                            data = new Array(0);
                                        }
                                        options.success({ Data: data, Total: 0 });
                                        $scope.isLoading = false;
                                        $scope.progress.complete();
                                        $scope.isSearching = false;
                                    }, function(error) {
                                        $scope.isLoading = false;
                                        $scope.progress.complete();
                                    });
                                initialDataLoad = false;
                                return $q.when(promise);
                            } else {
                                if (!initialDataLoad && $scope.clientId !== "") {
                                    var promise = documentsService.get($scope.clientId, $scope.filterId, $scope.searchTerm, options.data.page, options.data.pageSize, options.data.sort)
                                        .then(function(data) {
                                            options.success(data);
                                            $scope.isLoading = false;
                                            $scope.progress.complete();
                                            $scope.isSearching = false;
                                        }, function() {
                                            $scope.isLoading = false;
                                            $scope.progress.complete();
                                        });
                                    return $q.when(promise);
                                } else {
                                    initialDataLoad = false;
                                    options.success({ Data: new Array(0), Total: 0 });
                                }
                            }
                        }
                    },
                    schema: {
                        parse: function(response) {
                            if (!$.isArray(response.Data)) {
                                response.Data = [response.Data];
                            }
                            _.each(response.Data, function(elem) {
                                if (elem != null) {
                                    if (elem.ModifiedDate != null && momentDateTime(elem.ModifiedDate)._isAMomentObject) {
                                        elem.ModifiedDate = momentDateTime(elem.ModifiedDate + "+0000").format("DD/MM/YYYY h:mm A");
                                    }
                                }
                            });
                            return response;
                        },
                        data: "Data",
                        total: "Total"
                    },
                    serverPaging: true,
                    serverSorting: true,
                    pageSize: $scope.pageSize,
                    requestStart: function() {
                        if (initialDataLoad) {
                            kendo.ui.progress($("#loading"), true);
                        }
                    },
                    requestEnd: function() {
                        if (initialDataLoad) {
                            kendo.ui.progress($("#loading"), false);
                            initialDataLoad = false;
                        }
                    }
                },
                sortable: true,
                scrollable: {
                    virtual: true
                },
                selectable: function() {
                    if ($(window).width() < 768) {
                        return "row";
                    } else {
                        return "multiple, row";
                    }
                }(),
                dataBound: $scope.dataBoundEvtHandler,
                columns: (function() {
                    if ($(window).width() < 768) {
                        return [
                            { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Task", title: "Task", width: "18vw" },
                            { headerAttributes: { "class": "grid-center" }, field: "Title", title: "Title", width: "36vw", template: "<span title='${Title}'>${Title}</span>" },
                            { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Client.Name", title: "Client", width: "25vw", template: "<span title='${Client.Name}'>${Client.Name}</span>" },
                            { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "ModifiedDate", title: "Date Modified", width: "16vw", type: "date", template: "#= kendo.toString(ModifiedDate, 'dd/MM/yyyy') #" }
                        ];
                    } else {
                        return [
                            { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Task", title: "Task", width: "100px" },
                            { headerAttributes: { "class": "grid-center" }, field: "Title", title: "Title", width: "200px", template: "<span title='${Title}'>${Title}</span>" },
                            { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "Client.Name", title: "Client", width: "120px", template: "<span title='${Client.Name}'>${Client.Name}</span>" },
                            { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "CreatedBy", title: "Uploaded By", width: "120px" },
                            { headerAttributes: { "class": "grid-center" }, attributes: { "class": "grid-center" }, field: "ModifiedDate", title: "Date Modified", width: "80px", type: "date", template: "#= kendo.toString(ModifiedDate, 'dd/MM/yyyy') #" }
                        ];
                    }
                })()
            };

            $scope.dataBoundEvtHandler = function () {

                if ($scope.allClientsSelected === true) {
                    // Show the clients column
                    if ($("#docGrid").data("kendoGrid").columns[2].hidden === true) {
                        $("#docGrid").data("kendoGrid").showColumn(2);
                    }
                } else if ($scope.allClientsSelected === false) {
                    // Hide the clients column
                    if ($("#docGrid").data("kendoGrid").columns[2].hidden === false) {
                        $("#docGrid").data("kendoGrid").hideColumn(2);
                    }
                }
                displayNoResultsMessageIfNeeded($("#docGrid"));
                $timeout(function () {
                    events.trigger(constants.SYSTEM.EVENTS.GRIDRESIZE);
                }, 1);

                if ($scope.grid) {
                    // Logic taken from Admin bfGrid.js
                    var gridRows = $scope.grid.tbody.children("tr:not(.k-grouping-row,.no-results-row)");
                    if ($scope.selectedFileDataItem) {
                        // Reselect the previous selection if it's visible in the grid
                        var dataItems = $scope.selectedFileDataItem.length ? $scope.selectedFileDataItem : [$scope.selectedFileDataItem];
                        var prevSelectedRows = gridRows
                                .toArray()
                                .map(function (row) {
                                    return { Row: row, Item: $scope.grid.dataItem(row) };
                                })
                                .filter(function (item) {
                                    return dataItems.some(function (i) {
                                        return item.Item.Id === i.Id;
                                    });
                                })
                                .map(function (item) {
                                    return item.Row;
                                });
                        prevSelectedRows.length && $scope.grid.select(prevSelectedRows);
                    } else {
                        // Select the first row if no rows are currently selected
                        $scope.grid.select(gridRows.eq(0));
                    }
                }
            }

            $scope.$on("$viewContentLoaded", function (event) {
                getClientsList().then(function (clients) {
                    $scope.clients = [{ Name: "All", Id: "00000000-0000-0000-0000-000000000000" }].concat(clients);
                    setClient();
                    refreshTree();
                    refreshGrid();
                });
            });

            var refreshGrid = function () {
                $scope.grid.dataSource.read();
                $scope.isDisabled = true;
            }

            var refreshTree = function () {
                $scope.tree.dataSource.read();
                $scope.filterId = null;
                $scope.selectedFilter = null;
                $scope.isDisabled = true;
            }

            $scope.clearSearch = function () {
                clearDeepLinkedDocument();
                removeCachedSelection();
                $scope.searchForm.term = "";
                $scope.isSearching = true;
                events.trigger(constants.DOCUMENTS.EVENTS.SEARCH, $scope.searchForm.term);
            }

            var setClient = function() {
                if (!_.isUndefined($state.params.workingClientId) && $state.params.workingClientId != "") {
                    $scope.clients.forEach(function(client) {
                        if (client.Id == $state.params.workingClientId) {
                            $scope.selectedClient = client;
                            $scope.clientId = client.Id;
                        }
                    });
                } else {
                    $scope.selectedClient = $scope.clients[0];
                    $scope.clientId = $scope.clients[0].Id;
                }

                $scope.allClientsSelected = ($scope.clientId === "00000000-0000-0000-0000-000000000000");
            }

            var removeCachedSelection = function () {
                $scope.previousSelection = null;
            }
            var clearDeepLinkedDocument = function () {
                $state.params.documentId = null;
            }

            $scope.showHelp = function() {
                $scope.showHelpMessage = !$scope.showHelpMessage;
            }

            $scope.uploadsInProgress = function() {
                var uploads = _.where(uploadService.getFiles(), { aborted: false, error: false, errored: false, uploadComplete: false });
                if (uploads.length > 0) {
                    return true;
                }
                return false;
            }

        }
    ]);
})();
