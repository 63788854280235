'use strict';

angular.module('app.directives', [])

    .directive('appVersion', ['version', function (version) {
        return function (scope, elm, attrs) {
            elm.text(version.value);
        };
    }])
    .directive('appName', ['appName', function (appName) {
        return function (scope, elm, attrs) {
            elm.text(appName);
        };
    }]).directive('cacheGridSelection', [
        '$window', 'events', 'constants', '$q', function ($window, events, constants, $q) {
            return function (scope, element) {
                
                scope.cacheDataBound = function (grid) {
                    scope.resizeHandler();
                    if (scope.hasOwnProperty('dataBoundEvtHandler')) {
                        scope.dataBoundEvtHandler();
                    }
                    if (element[0].id === "docGrid") {
                        if (scope.$parent.hasOwnProperty('dataBoundEvtHandler')) {
                            scope.$parent.dataBoundEvtHandler();
                        }
                    }
                    if (!_.isNull(scope.previousSelection) && !_.isUndefined(scope.previousSelection)) {

                        var rows = element.find('.k-grid-content tbody tr');
                        var gridContext = grid;

                        if (_.isArray(scope.previousSelection)) {
                            _.each(scope.previousSelection, function (arrValue, arrIndex) {
                                _.each(rows, function (value, index) {
                                    if (gridContext._data[index]) {
                                        if (gridContext._data[index].Id === arrValue.Id) {
                                            $(value).addClass('k-state-selected');
                                        }
                                    }
                                });
                            });
                            scope.handleChange(scope.previousSelection);
                        } else {
                            _.find(rows, function (value, index) {
                                if (!_.isNull(gridContext._data[index]) && !_.isUndefined(gridContext._data[index])) {
                                    if (gridContext._data[index]) {
                                        if (gridContext._data[index].Id === scope.previousSelection.Id) {
                                            $(value).addClass('k-state-selected');
                                            scope.handleChange(gridContext._data[index]);
                                            return true;
                                        }
                                    }
                                }
                            });
                        }
                    }
                }

                scope.resizeHandler = function () {
                    var deferred = $q.defer();
                    deferred.resolve(true);
                    if (scope.hasOwnProperty('updatePageSize') || scope.$parent.hasOwnProperty('updatePageSize')) {
                        var dataArea = element.find(".k-grid-content");
                        if (dataArea[0]) {
                            var height = $("footer").offset().top - dataArea.offset().top - 20;

                            var rowsWeCanFit = 1;
                            while ((rowsWeCanFit * 35) < height) {
                                rowsWeCanFit++;
                            }

                            if (element[0].id === "docGrid") {
                                if (!_.isUndefined(scope.$parent.pageSize)) {
                                    if (scope.$parent.pageSize === rowsWeCanFit) {
                                        return deferred.promise;
                                    }
                                    scope.$parent.pageSize = rowsWeCanFit;
                                    scope.$parent.updatePageSize();
                                    return deferred.promise;
                                }
                            } else {
                                if (scope.pageSize === rowsWeCanFit) {
                                    return deferred.promise;
                                }
                                scope.pageSize = rowsWeCanFit;
                                scope.updatePageSize();
                                return deferred.promise;
                            }
                        }
                    }
                    return deferred.promise;
                }
            }
        }
    ])

.directive('focusMe', function ($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {
            var model = $parse(attrs.focusMe);
            scope.$watch(model, function (value) {
                if (value === true) {
                    $timeout(function () {
                        if (element[0][0] != undefined) {
                            element[0][0].focus();
                        } else {
                            // Some elements are different to others
                            element[0].children[0].children[1].children[0].focus();
                        }

                    });
                }
            });
            element.bind('blur', function () {
                scope.$apply(model.assign(scope, false));
            });
        }
    };
})

.directive('togglePassword', [
        function () {
            return {
                require: 'ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    var valueToWatch = attrs.togglePassword;

                    scope.$watch(valueToWatch, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            if (newValue) {
                                elem.attr('type', 'text');
                            } else {
                                elem.attr('type', 'password');
                            }
                        }
                    });
                }
            };
        }
])

.directive('passwordRequirements', function () {
    return {
        require: "ngModel",
        restrict: 'A',
        scope: false,
        link: function (scope, element, attrs) {
            scope.$watch(attrs.ngModel, function (newPassword, oldVal) {
                var regex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W\_]).{8,34})/;
                scope.satisfied = regex.test(newPassword);
            });
        }
    }
})
.directive('copyrightNotice', function() {
    return {
        link: function ($scope) {
            $scope.year = new Date().getFullYear();
        },
        template: "© {{year}} Business Fitness",
        restrict: "E",
        scope: {}
    };
});
