(function() {
    'use strict';
    var module = angular.module('bf.services.forgotPassword', []);

    module.service('forgotPasswordService', ['_', '$q', '$timeout', 'events', 'constants', 'dataStoreService', 'ENVIRONMENTAL', 
        function (_, $q, $timeout, events, constants, dataStoreService, ENVIRONMENTAL) {

            var apiSection = "/accounts/";

            function request(email) {
                var dataToSend = {
                    email: email
                };
                return dataStoreService.requestPasswordReset(dataToSend);;
            }

            function reset(password, token) {
                var deffered = $q.defer();

                var dataToSend = {};
                dataToSend.password = password;
                dataToSend.token = token;

                dataStoreService.resetPassword(dataToSend).then(function (response) {
                    deffered.resolve(response);
                });
                return deffered.promise;
            }

            function changePassword(oldPassword, newPassword) {
                var dataToSend = {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                };

                return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "changePassword", dataToSend);
            }

            return {
                requestPasswordReset: request,
                resetPassword: reset,
                changePassword: changePassword
            }
        }
    ]);
})();