angular.module('app')

.controller('StartCtrl', [
    '$scope', 
    '$location', 
    '$window', 
    'momentDateTime', 
    'events', 
    'dashboardService', 
    'loading', '$q', 
    '$timeout', 
    '$rootScope', 
    'announcementsService', 
    '_', 
    '$sce', 
    '$state',
    'dataLayerService', 
    function(
        $scope, 
        $location, 
        $window, 
        momentDateTime,
        events,
        dashboardService,
        loading,
        $q,
        $timeout,
        $rootScope,
        announcementsService,
        _,
        $sce,
        $state,
        dataLayerService) {
        $scope.$root.title = 'Portal';

        $scope.minDate = new Date('2000-01-01');
        $scope.maxDate = new Date();
        $scope.fromDateOpened = false;
        $scope.toDateOpened = false;
        $scope.openFrom = openFrom;
        $scope.openTo = openTo;
        $scope.fromDate = momentDateTime().subtract(7, 'days').toDate();
        $scope.toDate = new Date();
        $scope.nowDate = momentDateTime();

        $scope.taskAuditList = [];
        $scope.tasksPageSize = 4;
        $scope.tasksPage = 0;

        $scope.statistics = [];
        $scope.statisticsSelection = 1;
        $scope.taskSelection = 1;

        $scope.announcements = [];
        $scope.announcementsPageSize = 4;
        $scope.announcementsPage = 0;

        var sendDataLayerLoadMoreAnnouncementsEvent = function() {
            var dataLayerEvent = {
                "eventType": "user-dashboard-announcements",
                "event": "load-more-clicked",
                "firmId": $scope.auth.profile.firmId,
                "userId": $scope.auth.profile.portalUserID
            };

            dataLayerService.pushEvent(dataLayerEvent);
        };

        $scope.loadMoreAnnouncements = function(isPageLoad) {
            if (!isPageLoad) {
                sendDataLayerLoadMoreAnnouncementsEvent();
            }

            $scope.noMoreAnnouncements = false;
            $scope.announcementsTracker = loading.createTracker(false, 500);
            var deferred = $q.defer();
            $scope.announcementsTracker.track(deferred.promise);
            $scope.announcementsPage++;

            announcementsService.get($scope.announcementsPage, $scope.announcementsPageSize)
                .then(function(data) {
                        if (data.length === 0) {
                            $scope.noMoreAnnouncements = true;
                        } else {
                            _.each(data,
                                function(item, index) {
                                    $scope.announcements.push(item);
                                });
                        }
                        deferred.resolve(true);
                        $scope.isLoading = false;
                        $scope.progress.complete();
                    },
                    function(error) {
                        $scope.isLoading = false;
                        $scope.progress.complete();
                    });
        };

        function openFrom($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.fromDateOpened = true;
        }

        function openTo($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.toDateOpened = true;
        }

        $scope.getDaysDiff = function(activity) {
            return $scope.nowDate.diff(momentDateTime(activity.DateTime), 'days', true);
        };

        var getClearTextEventType = function(functionId) {
            return functionId === 1 ? "unsigned-click" : "signed-click";
        };

        var sendDataLayerSigningEvent = function($event) {
            var dataLayerEvent = {
                "eventType": "user-dashboard-signing",
                "event": getClearTextEventType(parseInt($event.target.id)),
                "firmId": $scope.auth.profile.firmId,
                "userId": $scope.auth.profile.portalUserID
            };

            dataLayerService.pushEvent(dataLayerEvent);
        };

        $scope.loadMoreTasks = function($event, isPageLoad) {
            if (!isPageLoad) {
                sendDataLayerSigningEvent($event);
            }

            $scope.tasksTracker = loading.createTracker(false, 500);
            var deferred = $q.defer();
            if (!_.isUndefined($event)) {
                if ($scope.taskSelection !== parseInt($event.target.id)) {
                    $scope.taskAuditList = [];
                    $scope.tasksPage = 0;
                }

                $scope.taskSelection = parseInt($event.target.id);
            }

            $scope.noMoreTasks = false;
            $scope.tasksPage++;
            dashboardService.getTaskDocuments($scope.taskSelection, $scope.tasksPage, $scope.tasksPageSize).then(
                function(data) {
                    if (data.length === 0) {
                        $scope.noMoreTasks = true;
                    } else {
                        _.each(data,
                            function(item, index) {
                                item.hasSignatures = item.UsersToSign.length + item.DocumentAdobeSigningUsers.length > 0;
                                $scope.taskAuditList.push(item);
                            });
                    }
                    deferred.resolve(true);
                });
            $scope.tasksTracker.track(deferred.promise);
        };
        
        $scope.$on('$viewContentLoaded', function (event) {
            var unsignedEventId = { id: 1 };
            $scope.loadMoreAnnouncements(true);
            $scope.loadMoreTasks({ target: unsignedEventId }, true);
            $scope.isLoading = false;
            $scope.progress.complete();
        });

        var sendDataLayerOpenReleaseNoteEvent = function() {
            var dataLayerEvent = {
                "eventType": "user-dashboard-releaseNote",
                "event": "releaseNote-clicked",
                "firmId": $scope.auth.profile.firmId,
                "userId": $scope.auth.profile.portalUserID
            };

            dataLayerService.pushEvent(dataLayerEvent);
        };

        $scope.openReleaseNotes = function() {
            sendDataLayerOpenReleaseNoteEvent();
            window.open(
                "http://portalhelp.hownowhq.com/support/solutions/articles/5000660465-software-release-notes",
                "_blank");
        };

        var sendDataLayerReleaseNoteEvent = function(item) {
            var dataLayerEvent = {
                "eventType": "user-dashboard-announcements",
                "event": "announcement-"+ item.Id +"-clicked",
                "firmId": $scope.auth.profile.firmId,
                "userId": $scope.auth.profile.portalUserID
            };

            dataLayerService.pushEvent(dataLayerEvent);
        };

        $scope.viewAnnouncement = function(item) {
            sendDataLayerReleaseNoteEvent(item);

            $state.go('viewannouncement', { announcementid: item.Id });
        };

        var sendDataLayerRecentEventsEvent = function(eventTitle) {
            var dataLayerEvent = {
                "eventType": "user-dashboard-recentEvents",
                "event": "recentEvent-"+ eventTitle +"-clicked",
                "firmId": $scope.auth.profile.firmId,
                "userId": $scope.auth.profile.portalUserID
            };

            dataLayerService.pushEvent(dataLayerEvent);
        };

        $scope.trackRecentEvent = function(eventTitle) {
            sendDataLayerRecentEventsEvent(eventTitle);
        };
    }
]);