(function() {
    'use strict';

    angular.module('bf.services.fileDownload', ['bf.constants']);

    angular.module('bf.services.fileDownload')
        .provider('$download', [
            function() {
                this.$get = [
                    '$rootScope', 'events', '$timeout', 'constants', function ($rootScope, events, $timeout, constants) {
                        return function(fileUrl) {
                            /**
                     * Here is how it's implemented for all browsers
                     * https://github.com/johnculviner/jquery.fileDownload/blob/master/src/Scripts/jquery.fileDownload.js#L246
                     */
                            var onready = function() {
                                if ($rootScope.$root.$$phase) {
                                    events.trigger(constants.DOWNLOADING.EVENTS.STARTED);
                                } else {
                                    $rootScope.$apply(function() {
                                        events.trigger(constants.DOWNLOADING.EVENTS.STARTED);
                                    });
                                }
                            };
                            $('<iframe id="downloadIFrame"/>').ready(onready).hide().prop('src', fileUrl).appendTo('body');
                            $timeout(function() {
                                $('#downloadIFrame').remove();
                            }, 20000);
                        };
                    }
                ];
            }
        ])
        .service('downloadService', [
            'events', '$http', 'constants', '$download', 'ENVIRONMENTAL', '$q',
            function (events, $http, constants, $download, ENVIRONMENTAL, $q) {

                var localDownloadFile = function (fileId, title) {
                    var deffered = $q.defer();

                    var dataToSend = {
                        Id: fileId,
                        FriendlyName: title
                    };

                    $http({
                        url: ENVIRONMENTAL.FILEAPI.BASEURL + "/api/download/get",
                        method: "POST",
                        data: dataToSend,
                        headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                    }).success(function (data, status, headers, config) {
                        if (status === 200) {
                            $download(data.fileUrl);
                            deffered.resolve();
                        } else {
                            deffered.reject(status);
                        }
                    }).error(function (data, status, headers, config) {
                        deffered.reject(status);
                    });
                    return deffered.promise;
                };

                var localDownloadAnnouncementFile = function (storagePath) {
                    var deffered = $q.defer();

                    var dataToSend = {
                        StoragePath: storagePath
                    };

                    $http({
                        url: ENVIRONMENTAL.FILEAPI.BASEURL + "/api/download/getAnnouncementDocument",
                        method: "POST",
                        data: dataToSend,
                        headers: { 'Content-Type': 'application/json', 'dataType': 'json' }
                    }).success(function (data, status, headers, config) {
                        if (status === 200) {
                            $download(data.fileUrl);
                        } else {
                            deffered.reject(status);
                        }
                    }).error(function (data, status, headers, config) {
                        deffered.reject(status);
                    });
                    return deffered.promise;
                };
                
                return {
                    downloadFile: localDownloadFile,
                    downloadAnnouncementFile: localDownloadAnnouncementFile
                };
            }
        ])
        .run([
            'downloadService', function(downloadService) {
            }
        ]);
})();