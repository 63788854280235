(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('DocumentsPrepareSignCtrl', [
        '$scope', '$modalInstance', 'selectedDoc', '$sce', 'constants', 'documentsService', '$q', 'ENVIRONMENTAL', '$modal', '$window', 'events', 'store', 'signatureService', 'logService', function ($scope, $modalInstance, selectedDoc, $sce, constants, documentsService, $q, ENVIRONMENTAL, $modal, $window, events, store, signatureService, logService) {

            $scope.title = selectedDoc.Title;
            $scope.close = function() {
                $modalInstance.dismiss('cancel');
            }
            $scope.signingComplete = false;
            $scope.documentLoading = true;
            $scope.messageIntegrity = false;
            $scope.signingFailed = false;

            $scope.reloadSignatureDocument = function () {
                $scope.documentLoading = true;
                $scope.signingFailed = false;
                $scope.signingComplete = false;
                $scope.messageIntegrity = false;
                $("#frame")[0].style.visibility = "hidden";

                setFrameUrl();
            }

            function setFrameUrl() {
                documentsService.getDownloadForSigning(selectedDoc.Id)
                    .then(function(data) {
                        return signatureService.identifyUser()
                            .then(function() {
                                return data;
                            });
                    })
                    .then(function (data) {
                        var profile = JSON.parse(JSON.stringify(store.get('profile')));
                        var userDetailsToParse = { email: profile.email, firstName: profile.firstName, surname: profile.lastName };
                        var userDetailsParam = "&userDetails=" + encodeURIComponent(JSON.stringify(userDetailsToParse));

                        $scope.url = $sce.trustAsResourceUrl(ENVIRONMENTAL.BASEURLS.SIGN + constants.DOCUMENTS.URLS.SIGNURL + encodeURIComponent(data.fileUrl) + constants.DOCUMENTS.URLS.SIGNTITLE + encodeURIComponent(document.Title) + userDetailsParam);
                    });
            }

            setFrameUrl();

            var messageHandler = function (e) {
                if (e.originalEvent.data === "signingComplete" && $scope.signingComplete === false) {
                    $scope.uploading = false;
                    setSigningCompletion(false);
                    $scope.uploadingProgress = 100;
                }
                if (e.originalEvent.data === "uploading" && $scope.uploading === false) {
                    $scope.uploading = true;
                    uploadingTimer();
                }
                if (e.originalEvent.data === "signingFailed" && $scope.signingFailed === false) {
                    logService.error(null, "Signing failed flag set on client side for document with id: " + selectedDoc.Id);
                    setSigningCompletion(true);
                }
                if (e.originalEvent.data == "error" && $scope.signingComplete == false) {
                    setSigningCompletion(true);
                    $scope.uploading = false;
                }
                if (e.originalEvent.data === "loadingComplete") {
                    if (!$scope.messageIntegrity) {
                        $scope.documentLoading = false;
                        $scope.messageIntegrity = true;
                        $scope.$apply();
                        $("#frame")[0].style.visibility = "visible";
                    }
                }
            };

            var setSigningCompletion = function(failed) {
                $scope.signingFailed = failed;
                $scope.signingComplete = true;
            };

            $scope.$watch('signingComplete', function (newVal, oldVal) {
                if (newVal && !$scope.signingFailed && !$scope.lock) {
                    logService.information("Signing complete flag set on client side for document with id: " + selectedDoc.Id);
                    $scope.lock = true;
                    documentsService.signingComplete(selectedDoc.Id).then(function (result) {
                        if (!_.isUndefined(result.Message)) {
                            if (result.Message === "document unauthorized for signing") {
                                logService.error(null, "failed response recieved from api for commiting signed document with id: " + selectedDoc.Id);
                            } else {
                                logService.error(JSON.Stringify(result), "Unexpected message returned from api for commiting signed document with id: " + selectedDoc.Id);
                            }
                        } else {
                            logService.information("Successful response recieved from api for committing signed document with id: " + selectedDoc.Id);
                            events.trigger(constants.DOCUMENTS.EVENTS.REFRESHREQUIRED);
                        }
                    });
                }
            });

            $($window).on("message", messageHandler);

            $scope.$on('$destroy',
                function() {
                    $($window).off('message', messageHandler);
                });

            var uploadingTimer = function() {
                $interval(function() {
                        $scope.uploadingProgress = $scope.uploadingProgress + 5;
                    },
                    1000,
                    20);
            };

        }]);
})();
