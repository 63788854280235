(function () {
    'use strict';

    angular.module('bf.components.files', ['bf.constants']);

    angular.module('bf.components.files')
        .directive('fileActions', [
            'constants', '_', function (constants, _) {
                return {
                    restrict: 'E',
                    scope: {
                        actionsFor: '@',
                        deleteFn: '&',
                        previewFn: '&',
                        previewEnabledForSelectedDoc: '=',
                        signFn: '&',
                        downloadFn: '&',
                        selectedFile: '=',
                        isAccountEligibleToSign: '=',
                        isAdobeSignableTask: '=',
                        confirmInProgress: '=',
                        allowDelete: '=',
                        isMultiSelect: '='
                    },
                    template: require('Site\\Components\\Views\\fileActions.html'),
                    replace: true,
                    link: function ($scope, element, attrs) {

                        if (!$scope.hasOwnProperty('actionsFor') || _.isUndefined($scope.actionsFor)) {
                            $scope.actionsFor = 'documents';
                        }

                        if (!$scope.hasOwnProperty('deleteFn') || _.isUndefined($scope.deleteFn)) {
                            $scope.deleteFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('previewFn') || _.isUndefined($scope.previewFn)) {
                            $scope.previewFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('signFn') || _.isUndefined($scope.previewFn)) {
                            $scope.signFn = angular.noop();
                        }

                        if (!$scope.hasOwnProperty('downloadFn') || _.isUndefined($scope.downloadFn)) {
                            $scope.downloadFn = angular.noop();
                        }

                        $scope.$on('$destroy', function () {
                            unsubscribeAllEvents();
                        });
                    }
                };

                function unsubscribeAllEvents() {
                }

            }
        ])
        .run([
            'events', 'constants', function (events, constants) {
                events.on(constants.SECURITY.EVENTS.LOGGEDIN, function (evt, userProfile) {

                });

                events.on(constants.SECURITY.EVENTS.LOGGEDOUT, function (evt, data) {
                });

            }
        ]);
})();
