(function() {
    'use strict';

    var module = angular.module('bf.services.accounts', []);

    module.service('accountsService', [
        '$q', '_', 'constants', 'dataStoreService', 'ENVIRONMENTAL', function($q, _, constants, dataStoreService, ENVIRONMENTAL) {
            var apiSection = "/accounts/";

            function localGet() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection);
            }

            function localUpdate(id, usersNewDetails) {
                var deffered = $q.defer();
                if (!_.isUndefined(usersNewDetails)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id, usersNewDetails);
                }
                deffered.reject();
                return deferred.promise;
            }

            function localGetById(id) {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + id);
            }
            function localResendInviteByToken(token) {
                var deferred = $q.defer();
                if (!_.isUndefined(token)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "resendInviteByToken/", { Token: token });
                }
                deferred.reject();
                return deferred.promise;
            }

            function localResendInviteByEmail(email) {
                var deferred = $q.defer();
                if (!_.isUndefined(email)) {
                    return dataStoreService.updateResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "resendInviteByEmail/", { Email: email });
                }
                deferred.reject();
                return deferred.promise;
            }

            return {
                get: localGet,
                getById: localGetById,
                update: localUpdate,
                resendInviteByToken: localResendInviteByToken,
                resendInviteByEmail: localResendInviteByEmail
            }
        }
    ]);
})();