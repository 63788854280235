(function () {
    'use strict';

    var module = angular.module('bf.services.dashboard.user', []);

    module.service('dashboardService', [
        '$q', 'constants', 'ENVIRONMENTAL', 'dataStoreService', function ($q, constants, ENVIRONMENTAL, dataStoreService) {
            var apiSection = '/audit/';

            function localDashboardItems() {
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'siteDashboard');
            }

            function localGetTaskDocuments(task, pageNumber, itemsPerPage) {
                var deffered = $q.defer();
                var search = '';
                var pre = '?';

                if (!_.isUndefined(pageNumber) && !_.isUndefined(itemsPerPage) && _.isNumber(pageNumber) && _.isNumber(itemsPerPage)) {
                    search += pre + 'pageNumber=' + pageNumber + '&itemsPerPage=' + itemsPerPage;
                    pre = '&';
                }
                return dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + 'taskAuditsForAccount/' + task + search);
            }

            return {
                dashboardItems: localDashboardItems,
                getTaskDocuments: localGetTaskDocuments
            }
        }
    ]);
})();