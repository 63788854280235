(function () {
    'use strict';

    var module = angular.module('bf.services.branding', []);

    module.service('brandingService', [
        '$q', '_', 'dataStoreService', '$http', '$filter', 'ENVIRONMENTAL', function ($q, _, dataStoreService, $http, $filter, ENVIRONMENTAL) {

            var apiSection = "/branding/";

            function getPath() {
                var deferred = $q.defer();
                dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + "path/" + "none").then(function (data) {
                    deferred.resolve(data);
                })
                    .catch(function (data) {
                        deferred.reject();
                    });
                return deferred.promise;
            }

            function getPathForTheme(theme, isAdminSite) {
                if (isAdminSite) {
                    return ENVIRONMENTAL.BASEURLS.BRANDING + theme + "/Admin/app.css";
                }
                
                return ENVIRONMENTAL.BASEURLS.BRANDING + theme + "/User/app.css";
            }

            function applyBrand(storagePath) {
                // Theme
                var head = document.getElementsByTagName("head")[0];
                var link = document.createElement("link");

                link.setAttribute("href", storagePath);
                link.setAttribute("rel", "stylesheet");
                link.setAttribute("type", "text/css");
                head.appendChild(link);
            }

            return {
                getPath: getPath,
                getPathForTheme: getPathForTheme,
                applyBrandCssToPage: applyBrand
            }
        }
    ]);

})();
