(function () {
    'use strict';

    angular.module('bf.components.notifications.user', ['bf.constants']);

    angular.module('bf.components.notifications.user')
        .service('userNotifications', [
            '_', '$timeout', '$q', 'momentDateTime', 'constants', 'dashboardService',
            function (_, $timeout, $q, momentDateTime, constants, dashboardService) {

                function getMyNotifications() {
                    return dashboardService.dashboardItems();
                }

                return {
                    getMyNotifications: getMyNotifications
                };
            }
        ])
        .directive('userNotifications', [
            'userNotifications', 'constants', '_', function (userNotifications, constants, _) {

                return {
                    restrict: 'E',
                    template: require('Site\\Components\\Views\\userNotifications.html'),
                    replace: true,
                    link: function ($scope, element, attrs) {

                        $scope.notifications = 14;

                        userNotifications.getMyNotifications().then(function(myNotifications) {

                            _.each(myNotifications, function(notification) {
                                notification.cssClass = getClassForNotificationType(notification.type);
                                notification.cssIconClass = getIconClassForNotificationType(notification.type);
                            });

                            $scope.notifications = myNotifications;
                        });

                        $scope.$on('$destroy', function () {
                            unsubscribeAllEvents();
                        });
                    }
                };

                function getClassForNotificationType(type) {
                    switch (type) {
                    case constants.NOTIFICATIONS.TYPES.ALERT.ID:
                        return constants.NOTIFICATIONS.TYPES.ALERT.CSSCLASS;
                    case constants.NOTIFICATIONS.TYPES.COMMENT.ID:
                        return constants.NOTIFICATIONS.TYPES.COMMENT.CSSCLASS;
                    default:
                        return constants.NOTIFICATIONS.TYPES.COMMENT.CSSCLASS;
                    }
                }

                function getIconClassForNotificationType(type) {
                    switch (type) {
                    case constants.NOTIFICATIONS.TYPES.ALERT.ID:
                        return constants.NOTIFICATIONS.TYPES.ALERT.ICONCLASS;
                    case constants.NOTIFICATIONS.TYPES.COMMENT.ID:
                        return constants.NOTIFICATIONS.TYPES.COMMENT.ICONCLASS;
                    default:
                        return constants.NOTIFICATIONS.TYPES.COMMENT.CSSCLASS;
                    }
                }

                function unsubscribeAllEvents() {
                }

            }
        ]);
})();