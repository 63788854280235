(function() {
    'use strict';

    var module = angular.module('bf.services.clients', []);

    module.service('clientsService', [
        '$q', '_', 'dataStoreService', function($q, _, dataStoreService) {

            function localReadFromStore() {
                var deffered = $q.defer();

                dataStoreService.readClientsFromStore().then(function(clientsFromStore) {
                    deffered.resolve(clientsFromStore);
                });

                return deffered.promise;
            }

            return {
                readFromStore: localReadFromStore
            }

        }
    ]);
})();