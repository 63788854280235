(function () {
    angular.module('app')
        .controller('CallbackCtrl', ['$rootScope', 'auth', 'store', 'events', 'brandingService', '$scope', 'constants', '$state', 'ENVIRONMENTAL',
            function ($rootScope, auth, store, events, brandingService, $scope, constants, $state, ENVIRONMENTAL) {
                init();

                $scope.doLogin = function () {
                    window.location.href = ENVIRONMENTAL.AUTH0.CALLBACKURL;
                };
            
                $scope.logOutThenGoToLoginPage = function () {
                    var urlParams = new URLSearchParams(window.location.href);
                    var token = urlParams.get('access_token');
                    var url = "https://" + ENVIRONMENTAL.AUTH0.DOMAIN +
                        "/connect/endsession" + "?id_token_hint=" + token + "&post_logout_redirect_uri=" +
                        ENVIRONMENTAL.AUTH0.CALLBACKURL;

                    window.location.replace(url);
                };

                function parseJwt(token) {
                    var base64Url = token.split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));

                    return JSON.parse(jsonPayload);
                };

                function onLoginFailed(errorMessage) {
                    $scope.user = "";
                    $scope.pass = "";
                    if (store.get('tokenAPI')) {
                        store.remove('tokenAPI');
                    }
                    if (store.get('tokenFiles')) {
                        store.remove('tokenFiles');
                    }
                    if (store.get('token')) {
                        store.remove('token');
                    }
                    if (store.get('profile')) {
                        store.remove('profile');
                    }

                    auth.isAuthenticated = false;
                    $scope.error = true;

                    if (errorMessage === undefined) {
                        $scope.errorMessage = 'Invalid username and/or password. Please contact your Portal provider if this problem persists.';
                    }
                    else {
                        $scope.errorMessage = errorMessage;
                    }
                    
                    $scope.progress.complete();
                }

                function init() {
                    $scope.error = false;
                    $scope.isLoading = false;
                    var urlParams = new URLSearchParams(window.location.href);
                    var token = urlParams.get('access_token');
                    var profile = parseJwt(token);
               
                    if (profile.roles === undefined) {
                        onLoginFailed("Current login does not have portal account access, please verify your user privileges.");
                        return;
                    }

                    if (profile.requireMFA === "true" && profile.mfa_enabled === undefined) {
                        if (store.get('tokenAPI')) {
                            store.remove('tokenAPI');
                        }
                        if (store.get('tokenFiles')) {
                            store.remove('tokenFiles');
                        }
                        if (store.get('token')) {
                            store.remove('token');
                        }
                        if (store.get('profile')) {
                            store.remove('profile');
                        }

                        auth.isAuthenticated = false;
                        
                        window.location.replace(
                                "https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/PortalManage/EnableAuthenticator?redirectUrl=" + ENVIRONMENTAL.AUTH0.CALLBACKURL + "&logoutOnComplete=true&theme=" + profile.firmId);
                        
                        return;
                    }

                    store.set('token', token);
                    store.set('tokenAPI', token);
                    store.set('tokenFiles', token);
                    store.set('profile', profile);
                    auth.profile = profile;
                    $scope.auth.profile = profile;

                    events.trigger(constants.SECURITY.EVENTS.LOGGEDIN);

                    if (store.get('branding') == null || store.get('branding') == "") {
                        brandingService.getPath().then(function (data) {
                            store.set('branding', data);
                            brandingService.applyBrandCssToPage(data);
                        });
                    }

                    var signingUrl = localStorage.getItem("signingUrl");
                    if (signingUrl) {
                        localStorage.removeItem("signingUrl");
                        window.location.replace(signingUrl);
                    }

                    // If Iframe portal, open HNCP in a new tab else go to state
                    if (window.self !== window.top) {
                        if ($stateParams.partial == "true") {
                            var url = $state.href('dashboard');
                            window.open(url, '_blank');
                            $scope.isLoading = false;
                            $scope.progress.complete();
                        } else {
                            if (!_.isUndefined($rootScope.returnToState) && !_.isNull($rootScope.returnToState)) {
                                $state.go($rootScope.returnToState.name, $rootScope.returnToStateParams);
                            } else {
                                $state.go('dashboard');
                            }
                        }
                    } else {
                        if (!_.isUndefined($rootScope.returnToState) && !_.isNull($rootScope.returnToState)) {
                            $state.go($rootScope.returnToState.name, $rootScope.returnToStateParams);
                        } else {
                            $state.go('dashboard');
                        }
                    }
                }
            }]);
})();